<template>
  <div>
    <label class="block text-sm">{{ label }}</label>
    <div>
      <input
        v-bind="$attrs"
        v-on="inputListeners"
        type="number"
        autocomplete="off"
        class="border border-gray-300 rounded-sm px-1 block focus:outline-none focus:ring ring-indigo-200"
        :value="value"
        :disabled="disabled"
        :class="optionalClasses"
        :min="min"
        :max="max"
        :step="step"
      />
      {{ unit }}
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "CmInputNumber",
  props: {
    value: [Number, String],
    label: String,
    unit: String,
    disabled: Boolean,
    min: {
      type: Number,
      default: -Infinity,
    },
    max: {
      type: Number,
      default: Infinity,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    classes: Object,
    required: Boolean,
    type: String,
  },
  computed: {
    inputListeners() {
      return Object.assign({}, this.$listeners, {
        input: event => this.$emit("input", event.target.value),
      });
    },
    optionalClasses() {
      return {
        ...this.classes,
        "bg-gray-200 text-gray-600": this.disabled,
      };
    },
    step() {
      return this.type && this.type.toLowerCase() == "integer" ? "1" : "any";
    },
  },
};
</script>
