<template>
  <div>
    <div class="relative htmltext" v-html="html">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "CmHtmlText",
  props: {
    html: String,
  },
};
</script>

<style>
.htmltext > h1 {
  @apply text-2xl;
}
.htmltext > h2 {
  @apply text-xl;
}
.htmltext > h3 {
  @apply text-lg;
}
</style>