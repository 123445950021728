import axios from "axios";
import to from "./to.js";

export default {
    getUserlist(params) {
        return to(axios.get("meta/users?" + params));
    },
    getUnitlist() {
        return to(axios.get("meta/units"));
    },
    getUseractivity(id) {
        return to(axios.get(`meta/users/log/${id}`));
    },
    saveUser(data) {
        if (data.id) {
            const id = data.id;
            return to(axios.patch(`meta/users/${id}`, data));
        } else {
            return to(axios.post(`meta/users`, data));
        }
    },
    getModules() {
        return to(axios.get("meta/modules"));
    },
    getRelationsForModule(name) {
        return to(axios.get(`meta/diagnosis_modules/${name}`));
    },
    deleteUser(id) {
        return to(axios.delete(`meta/users/${id}`));
    },
    setUserUnit(data) {
        return to(axios.post(`meta/users/addUserUnit/${data.id}`, data));
    },
    deleteUserUnit(data) {
        return to(axios.post(`meta/users/removeUserUnit/${data.id}`, data));
    }

}