import Vue from "vue";
import Vuex from "vuex";
import { getField, updateField } from "vuex-map-fields";
import createPersistedState from "vuex-persistedstate";

import auth from "./modules/auth";
import user from "./modules/user";
import monitoring from "./modules/monitoring";
import userExtra from "./modules/user-extra";
import patient from "./modules/patient";
import service from "./modules/service";
import meta from "./modules/meta";
import moduledata from "./modules/modules";
import visit from "./modules/visit";
import pm from "./modules/pm";
import admin from "./modules/admin";
import lab from "./modules/lab";

const modules = {
  auth,
  user,
  userExtra,
  monitoring,
  patient,
  service,
  meta,
  moduledata,
  visit,
  pm,
  admin,
  lab
};

Vue.use(Vuex);
const SET_LOADING = "SET_LOADING";
const SET_LOADING_NAME = "SET_LOADING_NAME";

export default new Vuex.Store({
  strict: true,
  state: {
    loading: false,
    loadingName: null
  },
  actions: {
    setLoading({ commit }, name) {
      commit(SET_LOADING, true);
      if (name !== null) {
        commit(SET_LOADING_NAME, name);
      }
    },
    unsetLoading({ commit }) {
      commit(SET_LOADING, false);
    },
    resetAllState({ dispatch }) {
      for (const currentModule in modules) {
        dispatch(`${currentModule}/resetState`);
      }
    }
  },
  mutations: {
    updateField,
    SET_LOADING(state, value) {
      state.loading = value;
    },
    SET_LOADING_NAME(state, value) {
      state.loadingName = value;
    }
  },
  getters: {
    getField
  },
  modules,
  plugins: [
    createPersistedState({
      key: "compos4",
      paths: ["user", "userExtra", "pm"]
    })
  ]
});
