<template>
  <div id="app" class="h-screen w-full font-sans">
    <router-view class="h-full w-full" :key="$route.fullPath" />
    <modal name="loggedout" :height="100">
      <div>
        <h3 class="text-lg font-semibold text-center">Du har blivit utloggad på grund av inaktivitet.</h3>
        <div class="flex w-full justify-center mt-4">
          <cm-button size="large" @click="relogin" tag="button">Logga in igen</cm-button>
        </div>
      </div>
    </modal>
    <v-dialog />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      missingAuth: false,
    };
  },
  created() {
    if (this.$store.state["auth"].authenticated !== true) {
      this.missingAuth = true;
      this.$store.dispatch("resetAllState");
      this.$router.push({ name: "login" });
    }
  },
  mounted() {
    if (!this.missingAuth) {
      this.$store.watch(
        state => state.auth.authenticated,
        value => {
          if (value === false) {
            this.notifyLogout();
          }
        }
      );
    }
  },
  methods: {
    notifyLogout() {
      this.$modal.show("loggedout");
    },
    relogin() {
      this.$modal.hide("loggedout");
      this.$store.dispatch("resetAllState");
      if (this.$route.name !== "login") {
        this.$router.replace({ name: "login" });
      }
    },
  },
};
</script>
