<template>
  <button
    :type="type"
    :disabled="isDisabled"
    class="inline-flex items-center border border-transparent rounded-md text-white focus:outline-none focus:ring transition ease-in-out duration-150"
    :class="optionalClasses"
    v-bind="$attrs"
    v-on="inputListeners"
  >
    <slot></slot>
  </button>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "CmButton",
  props: {
    size: {
      type: String,
      default: "medium",
      validator: val => ["xs", "small", "medium", "large"].includes(val),
    },
    visual: {
      type: String,
      default: "default",
      validator: val => ["default", "danger", "success", "primary"].includes(val),
    },
    type: {
      type: String,
      default: "button",
    },
    disabled: Boolean,
  },
  computed: {
    ...mapState({
      loading: state => state.loading,
    }),
    isDisabled() {
      return this.disabled || this.loading;
    },
    inputListeners() {
      return Object.assign({}, this.$listeners, {
        click: event => this.$emit("click", event),
      });
    },
    optionalClasses() {
      return {
        "text-xs px-0.5 py-0 leading-4": this.size === "xs",
        "text-sm px-1.5 py-0.5 leading-4": this.size === "small",
        "px-3 py-1.5 text-sm leading-4 font-medium": this.size === "medium",
        "text-lg px-2.5 py-1.5 leading-relaxed": this.size === "large",
        "border-gray-300 text-gray-700 bg-white hover:text-gray-500 ring-indigo-500 active:text-gray-800 active:bg-gray-50":
          !this.disabled && this.visual === "default",
        "bg-red-600 hover:bg-red-500 focus:border-red-700 ring-red-500 active:bg-red-700":
          !this.disabled && this.visual === "danger",
        "bg-green-600 hover:bg-green-500 focus:border-green-700 ring-green-500 active:bg-green-700":
          !this.disabled && this.visual === "success",
        "bg-indigo-600 hover:bg-indigo-500 focus:border-indigo-700 ring-red-200 active:bg-indigo-700":
          !this.disabled && this.visual === "primary",
        "bg-gray-100 text-gray-600 ring-indigo-500": this.isDisabled,
      };
    },
  },
};
</script>
