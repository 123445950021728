import { Factory } from "miragejs";
import faker from "faker";

const pad = (num, width) => {
  width = width || 2
  num += ""
  return num.length === width ? num : new Array(width - num.length + 1).join("0") + num;
}

const rand = (max, width) => {
  return pad(Math.floor((Math.random() * max) + 1), width)
}

const ssn = () => {
  return "19" + rand(99) + rand(12) + rand(31) + '-' + rand(9999, 4)
}

export default {
  patient: Factory.extend({
    first_name() {
      return faker.fake("{{name.firstName}}");
    },
    last_name() {
      return faker.fake("{{name.lastName}}");
    },
    ssn() {
      return ssn();
    },
    therapyIds() {
      return [2];
    },
    afterCreate(patient, server) {
      patient.update({
        diagnoses: server.createList('patientDiagnosis', faker.random.number(1) + 1),
        // therapies: server.createList('therapy', 1),
      });
    },
  })
}