export default [
  {
    id: 1,
    category: "derma",
    name: "Psoriasis",
    code: "psoriasis",
    alt_name: null,
    description: "Register för psoriasis. Registeransvarig är Mona Ståhle.",
  },{
    id: 2,
    category: "derma",
    name: "Hudcancer",
    code: "hudcancer",
    alt_name: null,
    description: "Register för hudcancer. Registeransvarig är Johan Heilborn.",
  },{
    id: 3,
    category: "derma",
    name: "Kontaktallergi",
    code: "allergi",
    alt_name: null,
    description: "Register för kontaktallergi",
  },{
    id: 4,
    category: "derma",
    name: "Blåsdermatos",
    code: "blasdermatos",
    alt_name: null,
    description: "Register för blåsdermatos.",
  },{
    id: 5,
    category: "derma",
    name: "Urtikaria",
    code: "urtikaria",
    alt_name: null,
    description: "Reigster för Urtikaria.",
  },{
    id: 6,
    category: "derma",
    name: "Atopiskt eksem",
    code: "atopiskt_eksem",
    alt_name: null,
    description: "Register för atopiskt eksem",
  }
]