import ApiAdmin from "@/api/admin";
import Vue from 'vue';

const initialState = () => {
    return {
        userlist: null,
        unitlist: null,
        useractivity: {},
        modules: null,
        moduleRelations: {}
    }
}

const state = initialState;

const RESET_STATE = "RESET_STATE";
const SET_USERLIST = "SET_USERLIST";
const SET_UNITLIST = "SET_UNITLIST"
const SET_USERACTIVITY = "SET_USERACTIVITY";
const UPDATE_USER_IN_LIST = "UPDATE_USER_IN_LIST";
const SET_MODULES = "SET_MODULES";
const SET_MODULE_RELATIONS = "SET_MODULE_RELATIONS";
const DELETE_USER_FROM_LIST = "DELETE_USER_FROM_LIST";

const actions = {
    resetState({ commit }) {
        commit(RESET_STATE);
    },
    async getUserlist({ commit }, { query }) {
        const [error, response] = await ApiAdmin.getUserlist(query);
        if (!error) {
            commit(SET_USERLIST, response.data)
            return true;
        }

        return Promise.reject(error);
    },
    async getUnitlist({ commit }) {
        const [error, response] = await ApiAdmin.getUnitlist();
        if (!error) {
            commit(SET_UNITLIST, response.data)
            return true;
        }

        return Promise.reject(error);
    },
    async getUseractivity({ commit }, id) {
        const [error, response] = await ApiAdmin.getUseractivity(id);
        if (!error) {
            commit(SET_USERACTIVITY, { data: response.data, id })
            return true;
        }

        return Promise.reject(error);
    },
    async saveUser({ commit, state }, data) {
        const [error, response] = await ApiAdmin.saveUser(data);
        if (!error) {
            commit(SET_USERACTIVITY, { data: null, id: data.id });

            if (state.userlist) {
                commit(UPDATE_USER_IN_LIST, response.data);
            }

            return true;
        }

        return Promise.reject(error);
    },
    async deleteUser({ commit }, id) {
        const [error, response] = await ApiAdmin.deleteUser(id);
        if (!error) {
            commit(DELETE_USER_FROM_LIST, id);

            return response;
        }

        return Promise.reject(error);
    },
    async setUserUnit({ commit }, data) {
        const [error, response] = await ApiAdmin.setUserUnit(data);
        if (!error) {
            commit(UPDATE_USER_IN_LIST, response.data);

            return response;
        }

        return Promise.reject(error);
    },
    async getModules({ commit }) {
        const [error, response] = await ApiAdmin.getModules();

        if (!error) {
            commit(SET_MODULES, response.data)

            return true;
        }

        return Promise.reject(error);
    },
    async getRelationsForModule({ commit }, name) {
        const [error, response] = await ApiAdmin.getRelationsForModule(name);

        if (!error) {
            commit(SET_MODULE_RELATIONS, { name, relations: response.data })
            return true;
        }

        return Promise.reject(error);
    },
    async deleteUserUnit({ commit }, data) {
        const [error, response] = await ApiAdmin.deleteUserUnit(data);
        if (!error) {
            commit(UPDATE_USER_IN_LIST, response.data);

            return response;
        }

        return Promise.reject(error);
    },
}
const mutations = {
    RESET_STATE(state) {
        const s = initialState();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    },
    SET_USERLIST(state, data) {
        state.userlist = data;
    },
    SET_UNITLIST(state, data) {
        state.unitlist = data;
    },
    SET_USERACTIVITY(state, { id, data }) {
        state.useractivity[id] = data;
    },
    UPDATE_USER_IN_LIST(state, data) {
        let update = state.userlist.data.findIndex(d => {
            return d.id === data.id
        });

        if (update === -1) {
            state.userlist.data.push(data);
        } else {
            state.userlist.data[update] = data;
        }
    },
    DELETE_USER_FROM_LIST(state, id) {
        let index = state.userlist.data.findIndex(d => {
            return d.id === id
        });

        state.userlist.data.splice(index, 1)
    },
    SET_MODULES(state, value) {
        state.modules = value;
    },
    SET_MODULE_RELATIONS(state, { name, relations }) {
        Vue.set(state.moduleRelations, name, relations)
    }
}
const getters = {}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
