<template>
  <div class="block relative max-w-lg rounded-sm">
    <select
      v-bind="$attrs"
      v-on="inputListeners"
      :value="value"
      :name="name"
      class="appearance-none w-full px-1 border pr-8 focus:outline-none focus:ring ring-indigo-200"
      :disabled="disabled"
      :required="required"
      :class="optionalClasses"
    >
      <option v-if="showEmpty" :value="null"></option>
      <option v-for="(option, key) in options" :key="key" :value="option[valueProp]" class="text-gray-800">
        {{ nameProp.split(".").reduce((p, c) => (p && p[c]) || null, option) }}
      </option>
    </select>
    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
      <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "CmSelect",
  props: {
    value: [String, Number],
    name: String,
    options: Array,
    disabled: Boolean,
    valueProp: {
      type: String,
      default: "value",
    },
    nameProp: {
      type: String,
      default: "translation.label",
    },
    classes: Object,
    required: Boolean,
    showEmpty: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      dataValue: undefined,
    };
  },
  computed: {
    inputListeners() {
      return Object.assign({}, this.$listeners, {
        input: event => this.$emit("input", event.target.value),
        change: event => this.$emit("change", event.target.value), // use change event for ie11 compatibility
      });
    },
    internalValue() {
      return this.dataValue ? this.dataValue : this.value;
    },
    optionalClasses() {
      return {
        ...this.classes,
        "bg-gray-200 text-gray-600": this.disabled,
      };
    },
  },
};
</script>
