import ApiPatient from "@/api/patient";
import { parse } from "date-fns";

const initialState = () => {
  return {
    info: null,
    restrictions: null,
    tabs: null,
    graphs: null,
    therapies: null,
    events: null,
    visits: null,
    pmRegistrations: null
  };
};

const state = initialState;

const SET_INFO = "SET_INFO";
const SET_RESTRICTIONS = "SET_RESTRICTIONS";
const SET_TABS = "SET_TABS";
const SET_GRAPHS = "SET_GRAPHS";
const SET_THERAPIES = "SET_THERAPIES";
const RESET_STATE = "RESET_STATE";
const SET_EVENTS = "SET_EVENTS";
const SET_VISITS = "SET_VISITS";
const SET_PM_REGISTRATIONS = "SET_PM_REGISTRATIONS";
const SET_LAB_STRUCTURE = "SET_LAB_STRUCTURE";
const SET_CONSENTS = "SET_CONSENTS";

const actions = {
  resetState({ commit }) {
    commit(RESET_STATE);
  },
  async getPersonBySsn({ commit }, data) {
    commit;
    const [error, response] = await ApiPatient.getPersonBySsn(data);
    error;

    return response;
  },
  async getPatientBySsn({ commit }, data) {
    commit;
    const [error, response] = await ApiPatient.getPatientBySsn(data);

    if (!error) {
      return response;
    }
  },
  async createPerson({ commit }, data) {
    commit;
    const [error, response] = await ApiPatient.createPerson(data);

    if (!error) {
      return response;
    }
  },
  async create({ commit }, data) { //eslint-disable-line
    const [error, response] = await ApiPatient.create(data);

    if (!error) {
      return response;
    }
  },
  async update({ dispatch }, data) {
    const [error, response] = await ApiPatient.update(data)

    if (!error) {
      dispatch("getInfo");
      return response;
    }
  },
  async delete({ commit }) {
    const [error, response] = await ApiPatient.delete()
    commit;
    if (!error) {
      return response;
    }
  },
  async getInfo({ commit, dispatch }) {
    const [error, response] = await ApiPatient.getInfo();
    if (!error) {
      const data = response.data;
      let restrictions = {
        dateMin: null,
      };

      const pidRegex = new RegExp(/\d{12}|\d{8}-\d{4}/);
      const birthDateRegex = new RegExp(/\d{4}-\d{2}-\d{2}/g);
      if (pidRegex.test(data.ssn)) {
        restrictions.dateMin = parse(data.ssn.substring(0, 8), "yyyyMMdd", new Date());
      } else if (birthDateRegex.test(data.birth_date)) {
        restrictions.dateMin = parse(data.birth_date, "yyyyMMdd", new Date());
      }

      restrictions.dateMax = new Date();

      commit(SET_RESTRICTIONS, restrictions);
      commit(SET_INFO, data);

      dispatch("view");
    }
  },
  async view({ commit, dispatch }) {
    let error, tabs, graphs, therapies, events, registrations;

    [error, tabs] = await ApiPatient.getTabs();
    if (!error) {
      commit(SET_TABS, tabs.data);
    }
    [error, graphs] = await ApiPatient.getGraphs();
    if (!error) {
      commit(SET_GRAPHS, graphs.data);
    }
    [error, therapies] = await ApiPatient.getTherapies();
    if (!error) {
      commit(SET_THERAPIES, therapies.data);
    }

    [error, events] = await ApiPatient.getEvents();
    if (!error) {
      commit(SET_EVENTS, events.data);
    }

    [error, registrations] = await ApiPatient.pmRegistrations();
    if (!error) {
      commit(SET_PM_REGISTRATIONS, registrations.data);
    }

    dispatch("getVisits");
  },
  async submitPost({ dispatch }, { formname, data, id }) {

    const [error, response] = await ApiPatient.submitPost({ formname, data, id });

    if (!error) {
      dispatch("moduledata/load", formname, { root: true });
      /**
       * maybe update state form data in order to improve performance and not load complete list from server
       */
      return response.data.id;
    }

    return Promise.reject(error);
  },
  async removePost({ dispatch }, { formname, id }) {
    const [error, response] = await ApiPatient.removePost({ formname, id });

    if (!error) {
      dispatch("moduledata/load", formname, { root: true });
      /**
       * maybe update state form data in order to improve performance and not load complete list from server
       */
      return response.data.id;
    }
  },
  //eslint-disable-next-line
  async getGraph({ commit }, name) {
    let error, response;

    let result = {};

    [error, response] = await ApiPatient.getGraph(name);
    if (!error) {
      result["lines"] = response.data;
      result["events"] = response.data.events;
    }
    [error, response] = await ApiPatient.getTherapies();
    if (!error) {
      result["therapies"] = response.data;
    }
    // [error, response] = await ApiPatient.getEvents();
    // if (!error) {
    //   result["events"] = response.data;
    // }

    return result;
  },
  async loadEvents({ commit }) {
    //eslint-disable-line
    const [error, response] = await ApiPatient.getEvents();
    if (!error) {
      const data = response.data;
      const events = data.map(d => {
        return {
          ...d,
          time: new Date(d.time),
        };
      });

      commit(SET_EVENTS, events);
    }
  },
  async updateDiagnosis({ dispatch }, data) {
    const [error, response] = await ApiPatient.diagnosisUpdate(data.id, data.data);
    if (!error) {
      dispatch("getInfo");
      return response;
    }
  },
  async deleteDiagnosis({ dispatch }, data) {
    const [error, response] = await ApiPatient.diagnosisDelete(data.id);
    if (!error) {
      dispatch("getInfo");
      return response;
    }
  },
  async addDiagnosis({ dispatch }, data) {
    //eslint-disable-line
    const [error, response] = await ApiPatient.diagnosisCreate(data);
    if (!error) {
      dispatch("getInfo");
      return response;
    }
  },
  async getVisits({ commit }) {
    const [error, response] = await ApiPatient.getVisits();
    if (!error) {
      commit(SET_VISITS, response.data)

    }
  },
  async pmRegistrations({ commit }) {
    const [error, response] = await ApiPatient.pmRegistrations();
    if (!error) {
      commit(SET_PM_REGISTRATIONS, response.data)
      return response;
    }
  },
  async pmImport({ commit }, data) {
    commit;
    const [error, response] = await ApiPatient.pmImport(data);
    if (!error) {
      return response;
    }
  },
  async pmExclude({ commit }, data) {
    commit;
    const [error, response] = await ApiPatient.pmExclude(data);
    if (!error) {
      return response;
    }
  },
  async labStructure({ commit }) {
    // const [error, response] = await ApiPatient.getLabstructure();
    const structure = {
      name: '',
      value: '',
      unit: ''
    }
    commit(SET_LAB_STRUCTURE, structure);
    // if (!error) {
    //   commit(SET_LAB_STRUCTURE, structure);
    //   return response;
    // }
  },
  async submitLab({ dispatch }, data) {
    const [error] = await ApiPatient.submitLab(data.data);

    if (!error) {
      dispatch("moduledata/loadLabData", null, { root: true });
      return true;
    }

    return Promise.reject(error);
  },
  async updateLab({ dispatch }, data) {
    const [error] = await ApiPatient.updateLab(data);

    if (!error) {
      dispatch("moduledata/loadLabData", null, { root: true });
      return true;
    }

    return Promise.reject(error);
  },
  async deleteLabRegistration({ dispatch }, id) {
    const [error] = await ApiPatient.deleteLabRegistration(id);
    if (!error) {
      dispatch("moduledata/loadLabData", null, { root: true });
      return true;
    }

    return Promise.reject(error);
  },
  async deleteLab({ dispatch }, data) {
    const [error, response] = await ApiPatient.deleteLab(data);
    if (!error) {
      dispatch("moduledata/loadLabData", null, { root: true });
      return response;
    }
  },
  async getConsents({ commit }) {
    const [error, response] = await ApiPatient.getConsents();
    if (!error) {
      commit(SET_CONSENTS, response)
      return response;
    }
  },
  async addConsent({ dispatch }, data) {
    const [error, response] = await ApiPatient.addConsent(data);
    if (!error) {
      dispatch("getInfo");
      return response;
    }
  },
  async updateConsent({ dispatch }, data) {
    const [error, response] = await ApiPatient.updateConsent(data.id, data.data);
    if (!error) {
      dispatch("getInfo");
      return response;
    }
  },
  async deleteConsent({ dispatch }, data) {
    const [error, response] = await ApiPatient.deleteConsent(data.id);
    if (!error) {
      dispatch("getInfo");
      return response;
    }
  }
};

const mutations = {
  RESET_STATE(state) {
    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_INFO(state, value) {
    state.info = value;
  },
  SET_RESTRICTIONS(state, value) {
    state.restrictions = { ...value };
  },
  SET_TABS(state, value) {
    state.tabs = value;
  },
  SET_GRAPHS(state, value) {
    state.graphs = value;
  },
  SET_THERAPIES(state, value) {
    state.therapies = value;
  },
  SET_EVENTS(state, value) {
    state.events = value;
  },
  SET_VISITS(state, value) {
    state.visits = value
  },
  SET_PM_REGISTRATIONS(state, value) {
    state.pmRegistrations = value;
  },
  SET_LAB_STRUCTURE(state, value) {
    state.labStructure = value;
  },
  SET_CONSENTS(state, value) {
    state.consents = value;
  }
};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
