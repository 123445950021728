/* eslint-disable */
import { createServer, ActiveModelSerializer, RestSerializer, Serializer } from "miragejs"
import fixtures from "./mirage/fixtures"
import factories from "./mirage/factories"
import models from "./mirage/models"
import routes from "./mirage/routes"
import seeds from "./mirage/seeds"

const AppSerializer = Serializer.extend({
  root: false,
  embed: true,
  serialize(object, request) {
    let data = Serializer.prototype.serialize.apply(this, arguments);
    if (object.modelName === "patient" && request.url.substr(0, request.url.indexOf('?')) === "/api/patients") {
      data = {
        data: data,
        total: data.length,
      }
    }

    return data;
  }
})

const config = environment => {
  const config = {
    environment,
    serializers: {
      application: AppSerializer,
      patient: AppSerializer.extend({
        include: ["diagnoses"],
      }),
      patientDiagnosis: AppSerializer.extend({
        include: ["diagnosis"],
      }),
    },
    factories,
    models,
    routes,
    fixtures,
    seeds,
  };

  return config;
};

export function makeServer({ environment = "development" } = {}) {
  return createServer(config(environment));
}
/* eslint-disable */