var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('date-picker',_vm._g({staticClass:"inline-block flex h-full",attrs:{"value":_vm.value,"locale":_vm.locale,"available-dates":_vm.availableDates,"mode":'date'},scopedSlots:_vm._u([(_vm.title !== '')?{key:"footer",fn:function(){return [_c('div',{staticClass:"bg-gray-100 text-center p-1 border-t rounded-b-lg"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]},proxy:true}:null,{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
var togglePopover = ref.togglePopover;
return [_c('div',{staticClass:"flex items-center"},[_c('input',_vm._g({ref:"inputel",staticClass:"border border-gray-300 rounded-sm px-1 block focus:outline-none focus:ring ring-indigo-200",attrs:{"id":_vm.name,"type":"text","autocomplete":"off","disabled":_vm.disabled},domProps:{"value":_vm.dateValue}},_vm.inputListeners)),_c('input',_vm._g({staticClass:"hidden",attrs:{"readonly":_vm.disabled,"disabled":_vm.disabled},domProps:{"value":inputValue}},inputEvents)),_c('button',{staticClass:"p-1 bg-blue-50 border border-blue-100 rounded-l focus:outline-none",class:{
            'hover:bg-blue-200 focus:bg-blue-500 focus:text-white focus:border-blue-500': !_vm.disabled,
            'cursor-not-allowed': _vm.disabled,
          },attrs:{"disabled":_vm.disabled,"tabindex":"-1"},on:{"click":function($event){$event.preventDefault();return togglePopover()}}},[_c('svg',{staticClass:"w-4 h-4 fill-current",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20"}},[_c('path',{attrs:{"d":"M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"}})])])])]}}],null,true)},_vm.inputListeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }