<template>
  <vue-slider
    ref="slider"
    :value="value"
    :style="{ padding: 0 }"
    :interval="interval"
    :contained="true"
    :disabled="disabled"
    :min="min"
    :max="max"
    v-on="inputListeners"
    class="bg-red-500"
  ></vue-slider>
</template>
<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

export default {
  name: "CmSlider",
  components: {
    VueSlider,
  },
  props: {
    value: [Number, String],
    name: String,
    disabled: Boolean,
    interval: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
  },
  computed: {
    inputListeners() {
      return Object.assign({}, this.$listeners, {
        change: value => this.$emit("input", value),
      });
    },
    optionalClasses() {
      return {
        "bg-gray-200 text-gray-600": this.disabled,
      };
    },
  },
};
</script>