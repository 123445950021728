<template>
  <div>
    <label class="block text-sm w-full">{{ label }}</label>
    <div class="relative">
      <input
        ref="inputel"
        v-bind="$attrs"
        v-on="inputListeners"
        type="text"
        autocomplete="off"
        class="border border-gray-300 rounded-sm px-1 block focus:outline-none focus:ring ring-indigo-200"
        :value="value"
        :disabled="disabled"
        :required="required"
        :class="optionalClasses"
        :maxlength="maxlength"
        :minlength="minlength"
      />
      {{ unit }}
    </div>
  </div>
</template>

<script>
export default {
  name: "CmInputText",
  props: {
    value: String,
    label: String,
    disabled: Boolean,
    maxlength: {
      type: Number,
      default: null,
    },
    minlength: {
      type: Number,
      default: null,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    classes: Object,
    required: Boolean,
    setFocus: Boolean,
    unit: {
      type: String,
      default: null,
    },
    focus: {
      type: Boolean,
      default: false,
    },
  },
  // mounted() {
  //   if (this.setFocus) {
  //     this.$refs.inputel.focus();
  //   }
  // },
  computed: {
    inputListeners() {
      return Object.assign({}, this.$listeners, {
        input: event => this.$emit("input", event.target.value),
      });
    },
    optionalClasses() {
      return {
        ...this.classes,
        "bg-gray-200 text-gray-600": this.disabled,
      };
    },
  },
};
</script>
