import ApiLab from '@/api/lab';

const initialState = () => {
    return {
        definitions: null
    }
}

const state = initialState;

const RESET_STATE = "RESET_STATE";
const SET_DEFINITIONS = "SET_DEFINITIONS";

const actions = {
    resetState({ commit }) {
        commit(RESET_STATE);
    },
    async loadDefinitions({ commit }) {
        const [error, response] = await ApiLab.loadDefinitions();

        if (!error) {
            commit(SET_DEFINITIONS, response.data)
        }
    }
}

const mutations = {
    RESET_STATE(state) {
        const s = initialState();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    },
    SET_DEFINITIONS(state, data) {
        state.definitions = data;
    }
}

const getters = {};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}