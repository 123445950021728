import Vue from "vue";
import { directive as onClickOutside } from "vue-on-click-outside";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import axios from "axios";
import VModal from "vue-js-modal";
import Toasted from "vue-toasted";
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
import VTooltip from 'v-tooltip'
import { makeServer } from "./server"
// import resize from "vue-element-resize-detector";
// Vue.use(resize)

import "splitpanes/dist/splitpanes.css";
import "@/assets/css/main.css";

Vue.use(VTooltip)

Vue.use(VModal, { dialog: true, dynamic: true, dynamicDefaults: { clickToClose: false } });
Vue.use(Toasted);

import ErrorMessage from "@/components/utils/ErrorMessage";
Vue.component("error-message", ErrorMessage);
import InfoMessage from "@/components/utils/InfoMessage";
Vue.component("info-message", InfoMessage);

Vue.directive("on-click-outside", onClickOutside);
Vue.directive('focus', {
  inserted: function (el) {
    el.focus();
  }
})
// Vue.directive('effect', {
//   inserted: function (el) {
//     console.log('directive update', el);
//   },
//   update: function (el, binding, vnode) {

//     console.log('directive update', el, binding, vnode);
//   }
// })
import { dateFormat } from "@/utils/date";

const requireComponent = require.context(
  "@/components/form/",
  true, //subfolders
  /[A-Z]\w+\.(vue|js)$/ // The regular expression used to match base component filenames
);

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName);

  const componentName = upperFirst(camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, "$1")));

  Vue.component(componentName, componentConfig.default || componentConfig);
});

axios.defaults.baseURL = process.env.VUE_APP_MIRAGE_BACKEND === 'yes' ? '/api' : process.env.VUE_APP_API_BASE_URL;

axios.defaults.withCredentials = false;

const token = store.state["auth"].token;

if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

axios.interceptors.request.use((config) => {
  return config;
});


axios.interceptors.response.use(
  function (response) {
    if (response?.data?.token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.token}`;
      localStorage.setItem("_token", response.data.token);
    }
    if (response.headers["x-token"]) {
      const token = response.headers["x-token"];

      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      localStorage.setItem("_token", token);
      // store.dispatch("user/refreshToken", token);
    }
    return response;
  },
  function (error) {
    if (typeof error.response === "undefined") {
      // toast.error('CORS error, or the network is down :(', 'Network error')
      // alert('CORS error, or the network is down :(', 'Network error');
      // store.dispatch("auth/corserror");
    }

    if (error.response && error.response.status === 401) {
      store.dispatch("auth/logout").then(() => {
        router.push({ name: 'login' }).catch(() => {
          //noop
        })
      });
    }
    return Promise.reject(error);
  }
);

Vue.prototype.$http = axios;
Vue.prototype.$dateFormat = dateFormat;

Vue.config.productionTip = false;

if (process.env.NODE_ENV === "development" && process.env.VUE_APP_MIRAGE_BACKEND === 'yes') {
  console.log('ok ok ok ');
  makeServer()
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount("#app");
