export default [
  {
    id: 1,
    code: "19144",
    name: "Fittja vårdcentral",
    tree_left: 134,
    tree_right: 135,
    tree_parent: null,
    tree_level: 4,
    enabled: null,
    hsa_id: null,
  }, {
    id: 2,
    code: "19145",
    name: "Älvsjö vårdcentral",
    tree_left: 132,
    tree_right: 133,
    tree_parent: null,
    tree_level: 4,
    enabled: null,
    hsa_id: null,
  }, {
    id: 3,
    code: "19150",
    name: "Liljeholmens vårdcentral",
    tree_left: 130,
    tree_right: 131,
    tree_parent: null,
    tree_level: 4,
    enabled: null,
    hsa_id: null,
  }, {
    id: 4,
    code: "19152",
    name: "Gröndals vårdcentral",
    tree_left: 128,
    tree_right: 129,
    tree_parent: null,
    tree_level: 4,
    enabled: null,
    hsa_id: null,
  }
]