<template>
  <div class="rounded-md bg-yellow-50 border border-yellow-200 p-2 my-2">
    <div class="flex items-center">
      <div class="">
        <div class="text-sm leading-5">
          <span>{{ message }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "InfoMessage",
  props: ["message"],
};
</script>
