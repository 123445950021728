import { getField, updateField } from "vuex-map-fields";
import ApiUser from "@/api/user";
import router from "@/router";

function initialState() {
  return {
    locale: "sv",
    activePatient: null,
    registries: null,
  };
}

const state = initialState;

const SET_INFO = "SET_INFO";
const RESET_STATE = "RESET_STATE";
const SET_ACTIVE_PATIENT = "SET_ACTIVE_PATIENT";
const SET_REGISTRIES = "SET_REGISTRIES";

const actions = {
  resetState({ commit }) {
    commit(RESET_STATE);
  },
  async getUserInfo({ commit, dispatch }) {
    const [error, response] = await ApiUser.getInfo();
    if (!error) {
      dispatch("getRegistries");
      commit(SET_INFO, response.data);
      return;
    }
  },
  async loadPatientList({ commit }, { query }) {
    const [error, response] = await ApiUser.getPatientList(query);
    if (!error) {
      commit("userExtra/SET_PATIENTLIST", response.data, { root: true });
    }
  },
  async activatePatient({ commit, dispatch }, patient) {
    const [error] = await ApiUser.activatePatient({ patient: patient });
    if (!error) {
      commit(SET_ACTIVE_PATIENT, +patient);
      await dispatch("patient/getInfo", null, { root: true });
      router.push({ name: "patient-basedata" });
    }
  },
  async deactivatePatient({ commit, state }) {
    if (state.activePatient) {
      const [error] = await ApiUser.deactivatePatient();
      if (!error) {
        commit(SET_ACTIVE_PATIENT, null);
      }
    }
  },
  async setPatient({ commit }, patient) {
    const [error] = await ApiUser.activatePatient({ patient: patient });
    if (!error) {
      commit(SET_ACTIVE_PATIENT, +patient);
    }
  },
  async getRegistries({ commit }) {
    const [error, response] = await ApiUser.registries();
    if (!error) {
      commit(SET_REGISTRIES, response.data);
    }
  },
};

const mutations = {
  updateField,
  RESET_STATE(state) {
    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_INFO(state, data) {
    state.info = data;
  },
  SET_ACTIVE_PATIENT(state, value) {
    state.activePatient = value;
  },
  SET_REGISTRIES(state, data) {
    state.registries = data;
  },
};

const getters = {
  getField,
  activePatient: state => state.activePatient
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
