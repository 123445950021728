import Vue from "vue";
import VueRouter from "vue-router";
// import Login from "../views/Login";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    alias: "",
    name: "",
    component: () => import(/* webpackChunkName: "login" */ "../views/Login"),
    children: [
      {
        path: "",
        name: "login",
        component: () => import(/* webpackChunkName: "login" */ "@/components/login/LoginStart"),
      },
      {
        path: "siths-verified",
        name: "login-siths-verified",
        component: () => import(/* webpackChunkName: "login" */ "@/components/login/SithsVerified"),
      },
      {
        path: "otp-select",
        name: "login-otp-select",
        component: () => import(/* webpackChunkName: "login" */ "@/components/login/OtpSelect"),
      },
      {
        path: "otp-verify",
        name: "login-otp-verify",
        component: () => import(/* webpackChunkName: "login" */ "@/components/login/OtpVerify"),
      },
      {
        path: "select-unit",
        name: "login-select-unit",
        component: () => import(/* webpackChunkName: "login" */ "@/components/login/SelectUnit"),
      },
    ],
  },
  {
    path: "/user",
    name: "",
    component: () => import(/* webpackChunkName: "user" */ "../views/User"),
    children: [
      {
        path: "",
        name: "user-start",
        component: () => import(/* webpackChunkName: "user-start" */ "@/components/user/StartPage"),
      },
      {
        path: "patients",
        name: "",
        component: () => import(/* webpackChunkName: "user-patients" */ "@/components/user/PatientPage"),
        children: [
          {
            path: "",
            name: "user-patients",
            component: () => import(/* webpackChunkName "user-patients" */ "@/components/user/patient/PatientList"),
          },
          {
            path: "create",
            name: "user-patients-create",
            component: () => import(/* webpackChunkName "user-patients" */ "@/components/user/patient/PatientCreate"),
          },
        ],
      },
      {
        path: "user-user-admin",
        name: "user-user-admin",
        component: () => import(/* webpackChunkName: "user-admin" */ "@/components/admin/UserAdmin"),
      },
      {
        path: "user-app-admin",
        name: "user-app-admin",
        component: () => import(/* webpackChunkName: "user-admin" */ "@/components/admin/AppAdmin"),
      },
      {
        path: "user-modules-admin",
        name: "user-modules-admin",
        component: () => import(/* webpackChunkName: "user-admin" */ "@/components/admin/ModulesAdmin"),
      },
      // {
      //   path: "reports",
      //   name: "",
      //   component: () => import(/* webpackChunkName: "user-monitoring" */ "@/components/user/ReportsPage"),
      //   children: [
      //     {
      //       path: "",
      //       name: "user-reports",
      //       component: () => import(/* webpackChunkName: "user-monitoring" */ "@/components/user/reports/Report"),
      //     },
      //   ]
      // },
      // {
      //   path: "monitoring",
      //   name: "",
      //   component: () => import(/* webpackChunkName: "user-monitoring" */ "@/components/user/MonitoringPage"),
      //   children: [
      //     {
      //       path: "",
      //       name: "user-monitoring",
      //       component: () => import(/* webpackChunkName: "user-monitoring" */ "@/components/user/monitoring/MonitoringLists"),
      //     },
      //   ]
      // }
    ],
  },
  {
    path: "/patient",
    name: "",
    component: () => import(/* webpackChunkName "patient" */ "@/views/Patient"),
    children: [
      {
        path: "",
        name: "patient-basedata",
        components: {
          north: () => import(/* webpackChunkName "patient" */ "@/components/patient/PatientBasedata"),
        },
      },
      {
        path: "edit",
        name: "patient-edit",
        component: () => import(/* webpackChunkName "patient-edit" */ "@/components/patient/PatientEdit"),
      },
      {
        path: "module",
        name: "patient-module",
        components: {
          north: () => import(/* webpackChunkName "patient" */ "@/components/graph/GraphContainer"),
          south: () => import(/* webpackChunkName "patient" */ "@/components/patient/module/ModuleGrid"),
        },
      },
      {
        path: "graphs",
        name: "patient-graphs",
        components: {
          north: () => import(/* webpackChunkName "patient" */ "@/components/graph/GraphContainer"),
        },
      },
      {
        path: "visits",
        name: "patient-visits",
        components: {
          north: () => import(/* webpackChunkName "patient" */ "@/components/patient/PatientVisits"),
        }
      },
      {
        path: "pm-registrations",
        name: "patient-pm-registrations",
        components: {
          north: () => import(/* webpackChunckName "patient" */ "@/components/patient/pm/PatientPmRegistrations"),

        },
      },
      {
        path: "lab",
        name: "lab-module",
        components: {
          north: () => import(/* webpackChunkName "patient" */ "@/components/graph/GraphContainer"),
          south: () => import(/* webpackChunkName "patient" */ "@/components/patient/lab/LabGrid"),
        },
      },
      {
        path: "therapy",
        name: "therapy-module",
        components: {
          north: () => import(/* webpackChunkName "patient" */ "@/components/graph/GraphContainer"),
          south: () => import(/* webpackChunkName "patient" */ "@/components/patient/therapy/TherapyGrid"),
        },
      }
    ],
  },
  {
    path: "/logout",
    name: "logout",
    redirect: () => ({ name: "login" }),
  },
  {
    path: "/pm",
    name: "",
    meta: {
      pm: true
    },
    component: () => import(/* webpackChunkName "pm" */ "@/views/Pm"),
    children: [
      {
        path: "/",
        name: "pm-login",
        meta: {
          pm: true
        },
        component: () => import(/* webpackChunkName: "pm" */ "@/components/pm/LoginStart"),
      },
      {
        path: "bankid-verified",
        name: "pm-login-bankid-verified",
        meta: {
          pm: true
        },
        component: () => import(/* webpackChunkName: "pm" */ "@/components/pm/BankidVerified"),
      },
      {
        path: "select-forms",
        name: "pm-select-forms",
        component: () => import(/* webpackChunkName: "pm" */ "@/components/pm/SelectForms"),
        meta: {
          pm: true
        },
      },
    ]
  },
  {
    path: "*",
    name: "not-found",
    component: () => import(/* webpackChunkName: "login" */ "@/views/error/Error404"),
  },
];

if (process.env.NODE_ENV === "development") {
  try {
    const req = require.context("@/components/dev/", true, /devroutes.js$/);

    let devRoutes;
    req.keys().forEach(file => {
      devRoutes = req(file);
    });
    routes.push(...devRoutes);
  } catch (e) {
    //eslint-disable-next-line
    console.log("no devroutes found. add src/components/dev/devroutes.js to add some");
  }
}


const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta?.pm === true) {

    const publicPages = ['pm', 'pm-login', 'pm-login-bankid-verified'];
    const authRequired = !publicPages.includes(to.name);
    const loggedIn = localStorage.getItem("patient_logged_in");

    // trying to access a restricted page + not logged in
    // redirect to login page
    if (authRequired && !loggedIn) {
      next('/pm');
    } else {
      next();
    }
  } else {
    const publicPages = ['login', 'login-siths-verified', 'logout', 'pm', 'pm-login', 'bankid-verified', 'select-forms'];

    const authRequired = !publicPages.includes(to.name);
    const loggedIn = localStorage.getItem("_token");

    // trying to access a restricted page + not logged in
    // redirect to login page
    if (authRequired && !loggedIn) {
      // console.group('auth-required. not logged in');
      // console.log('to: ', to.path, ' from:', from.path);
      // console.groupEnd();
      next('/login');
    } else {
      // console.group('normal route');
      // console.log('to: ', to.path, ' from:', from.path);
      // console.groupEnd()
      next();
    }
  }
});

router.onError(d => {
  console.log('router onerror:', d);
})

export default router;
