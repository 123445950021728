import { getField, updateField } from "vuex-map-fields";
import ApiMonitoring from "@/api/monitoring";

function initialState() {
  return {
    lists: null
  };
}

const SET_LISTS = "SET_LISTS";
const RESET_STATE = "RESET_STATE";

const state = initialState;

const actions = {
  resetState({ commit }) {
    commit(RESET_STATE);
  },
  async loadLists({ commit }) {
    const [error, response] = await ApiMonitoring.loadLists();
    if (!error) {
      commit(SET_LISTS, response.data);
    }
  }
};

const mutations = {
  updateField,
  RESET_STATE(state) {
    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_LISTS(state, data) {
    state.lists = data;
  }
};

const getters = {
  getField
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
