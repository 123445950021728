/**
 * load(formname)
 *  reset to empty state
 *  get data
 *  get layout
 *  get tabs
 *  set empty form-registration-placeholder "form"
 */
import Vue from 'vue'
import ApiModules from "@/api/modules";
import { dateFormat } from '@/utils/date';

const initialState = () => {
  return {
    data: {},
    layout: {},
    tab: {},
    form: {},
    lab: {}
  };
};

const state = initialState;

const SET_DATA = "SET_DATA";
const SET_LAYOUT = "SET_LAYOUT";
const SET_TAB = "SET_TAB";
const SET_LAB = "SET_LAB";
const RESET_STATE = "RESET_STATE";
const SET_FORMDATA = "SET_FORMDATA";
const SET_FORMFIELDDATA = "SET_FORMFIELDDATA";
const RESET_FORMDATA = "RESET_FORMDATA";

const actions = {
  async load({ commit }, formname) {

    const [error, { data, layout, tab }] = await ApiModules.load(formname);

    if (!error) {
      /**
       * set date and possibly time for the observation in module_registration from the
       * explicit fields observation_date and observation_time
       * on post/put, we reverse this and send date and time as observation_date and observation_time
       * in the request body
       */
      data.forEach(d => {
        if (!d.data) return;
        
        d.data.date = d.observation_date ? dateFormat(new Date(d.observation_date).toISOString()) : Date.now();
        if (d.observation_time) {
          d.data.time = d.observation_time
        }
      });

      commit(SET_DATA, { formname, data })
      commit(SET_LAYOUT, { formname, layout })
      commit(SET_TAB, { formname, tab })
      commit(RESET_FORMDATA, { formname, data: { data: {} } })
    }
  },
  async loadAll({ dispatch }, modulenames) {
    modulenames.forEach(m => {
      dispatch('load', m)
    })
  },
  resetState({ commit }) {
    commit(RESET_STATE);
  },
  resetFormdata({ commit }, formname) {
    commit(RESET_FORMDATA, { formname, data: { data: {} } });
  },
  setFormdata({ commit }, { formname, data }) {
    commit(SET_FORMDATA, { formname, data });
    return true;
  },
  setFormFielddata({ commit }, { formname, field, data }) {
    commit(SET_FORMFIELDDATA, { formname, field, data })
  },
  getLayoutForForm({ commit, getters }, name) {
    //to returns error and response in array, so response[1] should contain the data
    if (!getters.getFormLayout(name).length) {
      ApiModules.getLayout(name).then(response => {
        commit(SET_LAYOUT, { formname: name, layout: response[1].data })
      }).catch(e => console.log(e));
    }
  },
  async loadLabData({ commit }) {
    const [error, response] = await ApiModules.getLabData();

    if (!error) {
      commit(SET_LAB, response.data);
    }
  }
};

const mutations = {
  RESET_STATE(state) {
    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_DATA(state, { formname, data }) {
    if (!state.data[formname]) {
      Vue.set(state.data, formname, {});
    }
    Vue.set(state.data, formname, data)
    // state.data = data;
  },
  SET_TAB(state, { formname, tab }) {
    Vue.set(state.tab, formname, tab)
    // state.tab = data
  },
  SET_LAYOUT(state, { formname, layout }) {
    Vue.set(state.layout, formname, layout)
    // state.layout = data
  },
  SET_TAB_DATA(state, { formname, data }) {
    Vue.set(state.tabdata, formname, data)
    // state.tabdata = data;
  },
  SET_FORMFIELDDATA(state, { formname, field, data }) {
    Vue.set(state.form[formname]['data']['data'], field, data);
    // Vue.set(state.form, field, data);
  },
  SET_FORMDATA(state, { formname, data }) {
    let formdata = JSON.parse(JSON.stringify(data));

    if (!formdata.data.date || formdata.data.date !== formdata.observation_date) {
      formdata.data.date = formdata.observation_date ? dateFormat(new Date(formdata.observation_date).toISOString()) : Date.now();
      if (formdata.observation_time) {
        formdata.data.time = formdata.observation_time
      }
    }

    Vue.set(state.form[formname], 'data', formdata);
  },
  RESET_FORMDATA(state, { formname, data }) {
    if (!state.form[formname]) {
      Vue.set(state.form, formname, {})
    }
    Vue.set(state.form[formname], 'data', data);
  },
  SET_LAB(state, data) {
    state.lab = data;
  }

};
const getters = {
  getModuleData(state) {
    return formname => {
      return state.data[formname] ? state.data[formname] : []
    }
  },
  getFormData(state) {
    return formname => {
      return state.form[formname] ? state.form[formname]['data'] : []
    }
  },
  getFormTab(state) {
    return formname => {
      return state.tab[formname] ? state.tab[formname] : []
    }
  },
  getFormLayout(state) {
    return formname => {
      return state.layout[formname] ? state.layout[formname] : []
    }
  },
  getFormFieldData(state) {
    return (formname, fieldname) => {
      if (!state.form[formname] && !state.form[formname]['data'] && !state.form[formname]['data']['data'] && !state.form[formname]['data']['data'][fieldname]) {
        return null;
      }

      return state.form[formname] ? state.form[formname]['data']['data'][fieldname] : null
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
