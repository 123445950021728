<template>
  <div>
    <date-picker
      class="flex h-full"
      :value="value"
      :locale="locale"
      :available-dates="availableDates"
      :mode="'date'"
      v-on="inputListeners"
    >
      <template v-if="title !== ''" v-slot:footer>
        <div class="bg-gray-100 text-center p-1 border-t rounded-b-lg">
          {{ title }}
        </div>
      </template>
      <template v-slot="{ inputValue, inputEvents, togglePopover }">
        <div class="flex items-center">
          <input
            v-on="inputEvents"
            :value="inputValue"
            :readonly="disabled"
            :disabled="disabled"
            class="border border-gray-300 rounded-sm px-1 block focus:outline-none focus:ring ring-indigo-200"
          />
          <button
            :disabled="disabled"
            tabindex="-1"
            class="p-1 bg-blue-50 border border-blue-100 rounded-l focus:outline-none"
            :class="{
              'hover:bg-blue-200 focus:bg-blue-500 focus:text-white focus:border-blue-500': !disabled,
              'cursor-not-allowed': disabled,
            }"
            @click.prevent="togglePopover()"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="w-4 h-4 fill-current">
              <path
                d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
              ></path>
            </svg>
          </button>
        </div>
      </template>
    </date-picker>
  </div>
</template>


<script>
import DatePicker from "v-calendar/lib/components/date-picker.umd";
// import { isDate } from "lodash";

export default {
  name: "CmDatepicker2",
  components: {
    DatePicker,
  },
  props: {
    value: [String, Date, Number],
    name: String,
    disabled: Boolean,
    label: String,
    locale: {
      type: String,
      default: "sv",
    },
    errorclass: Object,
    classes: Object,
    maxDate: [Date, null],
    minDate: [Date, null],
    valueType: {
      type: String,
      default: "date",
    },
    title: {
      type: String,
      default: "",
    },
  },
  computed: {
    availableDates() {
      return {
        start: this.minDate ? this.minDate.getTime() : null,
        end: this.maxDate ? this.maxDate.getTime() : Date.now(),
      };
    },
    inputListeners() {
      return Object.assign({}, this.$listeners, {
        input: value => {
          this.$emit("input", value);
        },
      });
    },
  },
  methods: {},
};
</script>
