import axios from "axios";
import to from "./to.js";

export default {
  unitList() {
    return to(axios.get("user/units"));
  },
  getInfo() {
    return to(axios.get("user"));
  },
  setCurrentUnit(payload) {
    return to(axios.post("user/units", payload));
  },
  getMenu() {
    return to(axios.get("user/menu"));
  },
  getPatientList(params) {
    return to(axios.get("patients?" + params));
  },
  activatePatient(payload) {
    return to(axios.post("patients/activate", payload));
  },
  deactivatePatient() {
    return to(axios.post("patients/deactivate"));
  },
  updateLocale(payload) {
    const localeData = {
      locale: payload
    };

    return to(axios.post("user/locale", localeData));
  },
  registries() {
    return to(axios.get("user/registries"));
  },
  getLog() {
    return to(axios.get(`backend/log/user/1`));
  }
};
