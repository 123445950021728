// import ApiPm from "@/api/pm";

function initialState() {
    return {
        pmForms: null,
        pmData: null,
        pmToken: null,
        pmLoggedIn: localStorage.getItem("patient_logged_in") === "true" ? true : false
    }
}
const state = initialState;

const RESET_STATE = "RESET_STATE";
const SET_PM_FORMS = "SET_PM_FORMS";
const SET_PM_DATA = "SET_PM_DATA";
const SET_PM_TOKEN = "SET_PM_TOKEN";
const LOGOUT = "LOGOUT";

const actions = {
    resetState({ commit }) {
        commit('RESET_STATE')
    },
    setPmForms({ commit }, data) {
        commit(SET_PM_FORMS, data)
    },
    setPmData({ commit }, data) {
        commit(SET_PM_DATA, data)
    },
    setToken({ commit }, data) {
        commit(SET_PM_TOKEN, data)
    },
    logout({ commit }) {
        commit(RESET_STATE);
        commit(LOGOUT);
    }
};
const mutations = {
    RESET_STATE(state) {
        const s = initialState();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
        localStorage.setItem("patient_logged_in", false);
    },
    SET_PM_FORMS(state, value) {
        state.pmForms = value;
    },
    SET_PM_DATA(state, value) {
        state.pmData = value;
    },
    SET_PM_TOKEN(state, value) {
        localStorage.setItem("patient_logged_in", true);
        state.pmLoggedIn = true;
        state.pmToken = value;
    },
    LOGOUT(state) {
        state.pmLoggedIn = false
        localStorage.setItem("patient_logged_in", false);
    }

};
const getters = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
