import visitApi from '@/api/visit';

const initialState = () => {
    return {
        visits: [],
        forms: []
    }
}
const RESET_STATE = "RESET_STATE";
const SET_FORMS = "SET_FORMS";
const SET_VISITS = "SET_VISITS";
const SET_VISIT = "SET_VISIT";

const state = initialState;

const actions = {
    resetState({ commit }) {
        commit(RESET_STATE);
    },
    setForms({ commit }, forms) {
        commit(SET_FORMS, forms)
    },
    async submit({ rootGetters }, id) {
        const visitModuleNames = rootGetters['meta/visitModuleNames'];
        const activePatient = rootGetters['user/activePatient'];
        const data = visitModuleNames.map(d => {
            return {
                module: d,
                data: rootGetters['moduledata/getFormData'](d)
            }
        })

        const baseVisit = data.find(d => d.module === 'visit');
        const visitModules = data.filter(d => d.module !== 'visit');

        const moduleRegistrations = visitModules.map(d => {
            let moduledata = {
                module: d.module,
                observation_date: d.observation_date || baseVisit.data.data.visit_date || baseVisit.data.data.date,
                patient: activePatient,
                data: d.data.data
            }
            if (d.data.id) {
                moduledata.id = d.data.id;
            }
            return moduledata;
        })

        const visitdata = {
            patient: activePatient,
            ...baseVisit.data.data,
            module_registrations:
                moduleRegistrations

        }

        const [error, response] = await visitApi.create(visitdata, id)
        console.log(error, response);


    },
    async load({ commit }, id) {
        const [error, response] = await visitApi.load(id);

        if (!error) {
            commit(SET_VISIT, response.data)
        }
    },
    async loadAll({ commit }) {
        const [error, response] = await visitApi.loadAll();

        if (!error) {
            commit(SET_VISITS, response.data)
        }
    }
}

const mutations = {
    RESET_STATE(state) {
        const s = initialState();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    },
    SET_FORMS(state, forms) {
        state.forms = forms;
    },
    SET_VISIT(state, data) {
        if (state.visits.find(d => {
            return d.id === data.id
        })) {
            state.visits.map(d => {
                return d.id === data.id ? data : d;
            })
        } else {
            state.visits.push(data);
        }
    },
    SET_VISITS(state, data) {
        state.visits = data;
    }
}

const getters = {
    visits: state => state.visits,
    visitById: state => (id) => {
        return state.visits.find(d => d.id === id)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
