// import { Model, hasMany } from "miragejs";
import { Model, hasMany, belongsTo } from "miragejs";

export default {
  user: Model.extend({
    units: hasMany(),
    registries: hasMany(),
  }),
  unit: Model,
  registry: Model,
  diagnosis: Model,
  therapy: Model,
  patient: Model.extend({
    diagnoses: hasMany("patientDiagnosis"),
    therapies: hasMany("therapy"),
  }),
  patientDiagnosis: Model.extend({
    // patient: belongsTo(),
    diagnosis: belongsTo(),
  }),
}