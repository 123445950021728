import axios from "axios";
import to from "./to.js";

export default {
  create(data, id) {
    if (id) {
      return to(axios.put(`patient/visits/${id}`, data))
    }
    return to(axios.post('patient/visits', data))
  },
  load(id) {
    return to(axios.get(`patient/visits/${id}`))
  },
  loadAll() {
    return to(axios.get('patient/visits'))
  }
};
