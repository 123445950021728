<template>
  <textarea
    class="border rounded-sm w-full p-1 focus:outline-none focus:ring ring-indigo-200"
    v-on="inputListeners"
    :name="name"
    :minlength="minlength"
    :maxlength="maxlength"
    :value="value"
    :disabled="disabled"
    :class="optionalClasses"
  />
</template>
<script>
export default {
  name: "CmTextarea",
  props: {
    value: String,
    name: String,
    disabled: Boolean,
    maxlength: {
      type: Number,
      default: null,
    },
    minlength: {
      type: Number,
      default: null,
    },
    classes: Object,
  },
  computed: {
    inputListeners() {
      return Object.assign({}, this.$listeners, {
        input: event => this.$emit("input", event.target.value),
      });
    },
    optionalClasses() {
      return {
        ...this.classes,
        "bg-gray-200 text-gray-600": this.disabled,
      };
    },
  },
};
</script>
