import ApiService from "@/api/service";

function initialState() {
    return {}
}
const state = initialState;

const actions = {
    resetState({ commit }) {
        commit('RESET_STATE')
    },
    async spar({ commit }, ssn) { //eslint-disable-line
        const [error, response] = await ApiService.spar({ ssn: ssn });
        if (error) throw new Error(error.data.message)

        return response
    }
};
const mutations = {
    RESET_STATE(state) {
        const s = initialState();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    }
};
const getters = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
