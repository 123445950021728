import { Factory } from "miragejs";
import faker from "faker";

export default {
  patientDiagnosis: Factory.extend({
    // patientId() {
    //   return faker.random.number(99) + 1;
    // },
    diagnosisId() {
      return faker.random.number(131) + 1;
    },
    date() {
      return new Date(faker.fake('{{date.past}}')).toISOString().split('T')[0];
    },
    // afterCreate(patientDiagnosis, server) {
    //   const messages = server.createList('message', randomNumber(10), { user });

    //   user.update({ messages });
    // },
  })
}