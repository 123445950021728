<template>
  <div class="rounded-md bg-red-100 border border-red-500 p-4">
    <div class="flex items-center">
      <!-- <div class="flex-shrink-0">
        <svg class="h-5 w-5 text-red-600" fill="currentColor" viewBox="0 0 20 20">
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
            clip-rule="evenodd"
          />
        </svg>
      </div> -->
      <div class="">
        <div class="text-sm leading-5 text-red-700">
          <span>{{ message }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ErrorMessage",
  props: ["message"],
};
</script>
