import ApiMeta from "@/api/meta";

const initialState = () => {
  return {
    formname: null,
    registryDiagnoses: null,
    diagnoses: null,
    columns: null,
    formstructure: null,
    visitTypes: null,
    visitModules: null,
    // visitVariable: null
  };
};

const state = initialState;

const SET_COLUMNS = "SET_COLUMNS";
const RESET_STATE = "RESET_STATE";
const SET_FORMSTRUCTURE = "SET_FORMSTRUCTURE";
const SET_REGISTRY_DIAGNOSES = "SET_REGISTRY_DIAGNOSES";
const SET_DIAGNOSES = "SET_DIAGNOSES";
const SET_VISIT_TYPES = "SET_VISIT_TYPES";
const SET_VISIT_MODULES = "SET_VISIT_MODULES";
const SET_STUDIES = "SET_STUDIES";

const actions = {
  resetState({ commit }) {
    commit(RESET_STATE);
  },
  async loadModule({ commit }, name) {
    commit(RESET_STATE);

    const [error, response] = await ApiMeta.getColumns(name);
    if (!error) {
      commit(SET_COLUMNS, { name, data: response.data });
    }
  },
  async loadFormStructure({ commit }, name) {
    const [error, response] = await ApiMeta.getFormStructure(name);
    if (!error) {
      commit(SET_FORMSTRUCTURE, { name, data: response.data });
    }
  },
  async registryDiagnoses({ commit }) {
    const [error, response] = await ApiMeta.registryDiagnoses();
    if (!error) {
      commit(SET_REGISTRY_DIAGNOSES, response.data);
    }
  },
  async diagnoses({ commit }) {
    const [error, response] = await ApiMeta.diagnoses();
    if (!error) {
      commit(SET_DIAGNOSES, response.data);
    }
  },
  async visitTypes({ commit }) {
    // const [error, response] = await ApiMeta.visitTypes();

    const data = [
      { value: 'visit-yearly', name: 'Nybesök/årskontroll' },
      { value: 'visit-revisit', name: 'Återbesök' },
      { value: 'visit-child', name: 'Barnbesök' },
    ];
    commit(SET_VISIT_TYPES, data);

  },
  async modulesForVisitType({ commit, dispatch }, type) {
    const [error, response] = await ApiMeta.visitModules(type);

    if (!error) {
      commit(SET_VISIT_MODULES, { type, data: response.data });
    }

    response.data.forEach(d => {
      dispatch('moduledata/resetFormdata', d.name, { root: true })
      dispatch("moduledata/getLayoutForForm", d.name, { root: true });
    });
  },
  async loadOptions({ commit }) {
    const [error, response] = await ApiMeta.loadOptions();
    if (!error) {
      // const data = response.data;
      // const lists = data.map(d => d.optionslist)
      commit(SET_VISIT_MODULES, { data: response.data });
    }
  },
  async studies({ commit }) {
    const [error, response] = await ApiMeta.studies();
    if (!error) {
      commit(SET_STUDIES, { data: response.data })
    }
  }

};

const mutations = {
  RESET_STATE(state) {
    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_COLUMNS(state, { data }) {
    state.columns = data;
  },
  SET_FORMSTRUCTURE(state, { name, data }) {
    state.formname = name;
    state.formstructure = data;
  },
  SET_REGISTRY_DIAGNOSES(state, data) {
    state.registryDiagnoses = data;
  },
  SET_DIAGNOSES(state, data) {
    state.diagnoses = data;
  },
  SET_VISIT_TYPES(state, data) {
    state.visitTypes = data;
  },
  SET_VISIT_MODULES(state, { data }) {
    state.visitModules = data;
  },
  SET_STUDIES(state, { data }) {
    state.studies = data;
  },
};
const getters = {
  tabcolumns: state => {
    if (!state.columns) return [];

    return (
      state.columns
        //   .filter(d => d.subtype === "tab")
        .map(d => {
          return {
            name: d.name,
            label: d.translation.label,
            description: d.translation.description,
          };
        })
    );
  },
  visitModuleNames: state => state.visitModules.map(d => d.name)
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
