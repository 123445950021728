import store from '@/store';

const to = function (promise) {

  store.dispatch('setLoading', null, { root: true })

  return promise
    .then(response => {
      return [null, response];
    })
    .catch(error => {
      return [error];
    })
    .finally(() => {
      store.dispatch('unsetLoading', null, { root: true })
    });
};

export default to;
