import axios from "axios";
import to from "./to.js";

export default {
  create(data) {
    return to(axios.post("patients", data));
  },
  update(data) {
    return to(axios.patch("patient", data));
  },
  delete() {
    return to(axios.delete("patient"))
  },
  getInfo() {
    return to(axios.get("patient/info"));
  },
  getTabs() {
    return to(axios.get("patient/tabs"));
  },
  getGraphs() {
    return to(axios.get("patient/overview"));
  },
  getTherapies() {
    return to(axios.get("patient/therapies"));
  },
  getConsents() {
    return to(axios.get("patient/consents"));
  },
  submitPost({ formname, data, id }) {
    const url = `patient/forms/${formname}`;

    /**
     * extract date and possibly time from the data, and send as separate fields in the request
     */
    const observation_date = data["date"];
    delete data["date"];

    const observation_time = data["time"] ?? null;
    if (observation_time) {
      delete data["time"];
    }

    const postdata = {
      observation_date,
      observation_time,
      data
    };
    if (id !== null) {
      return to(axios.put(`${url}/${id}`, postdata));
    }

    return to(axios.post(url, postdata));
  },
  removePost({ formname, id }) {
    const url = `patient/forms/${formname}`;

    return to(axios.delete(`${url}/${id}`));
  },
  getGraph(name) {
    return to(axios.get(`patient/graphs/${name}`));
  },
  getEvents() {
    return to(axios.get("patient/log"));
  },
  diagnosisCreate(data) {
    return to(axios.post(`patient/diagnoses`, data));
  },
  diagnosisUpdate(id, data) {
    return to(axios.patch(`patient/diagnoses/${id}`, data));
  },
  diagnosisDelete(id) {
    return to(axios.delete(`patient/diagnoses/${id}`));
  },
  createPerson(data) {
    return to(axios.post('people', data));
  },
  getPersonBySsn(data) {
    return to(axios.get(`people?ssn=${data}`));
  },
  getPatientBySsn(data) {
    return to(axios.get(`patients?person_ssn=${data}`));
  },
  getVisits() {
    return to(axios.get(`patient/visits`));
  },
  pmRegistrations() {
    return to(axios.get("patient/pm/registrations"));
  },
  pmImport(data) {
    return to(axios.post("patient/pm/import", data));
  },
  pmExclude(data) {
    return to(axios.post("patient/pm/exclude", data));
  },
  getTherapyStructure() {
    return to(axios.get("patient/therapies/structure"));
  },
  getLabstructure() {
    return to(axios.get("patient/labStructure"));
  },
  submitLab(data) {
    if (data.id && data.id !== null) {
      return to(axios.patch(`patient/lab/${data.id}`, data));
    }
    delete data.id;

    return to(axios.post("patient/lab", data));
  },
  updateLab(data) {
    return to(axios.patch("patient/lab", data));
  },
  deleteLabRegistration(id) {
    return to(axios.delete(`patient/lab/${id}`));
  },
  deleteLab(date) {
    return to(axios.delete(`patient/lab/${date}`));
  },
  addConsent(data) {
    return to(axios.post("patient/consents", data));
  },
  updateConsent(id, data) {
    return to(axios.patch(`patient/consents/${id}`, data));
  },
  deleteConsent(id) {
    return to(axios.delete(`patient/consents/${id}`));
  }
};
