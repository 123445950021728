import { getField, updateField } from "vuex-map-fields";

function initialState() {
  return {
    id: null,
    ssn: null,
    first_name: null,
    last_name: null,
    patientlist: null,
    currentUnitId: null,
    currentUnitName: null
  };
}

const state = initialState;
const RESET_STATE = "RESET_STATE";

const actions = {
  resetState({ commit }) {
    commit(RESET_STATE);
  }
};

const mutations = {
  updateField,
  RESET_STATE(state) {
    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_PATIENTLIST(state, data) {
    state.patientlist = data;
  },
  SET_CURRENT_UNIT_NAME(state, value) {
    state.currentUnitName = value;
  },
  SET_CURRENT_UNIT_ID(state, value) {
    state.currentUnitId = value;
  },
  SET_UNIT_LIST(state, data) {
    state.unitList = data;
  }
};

const getters = {
  getField
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
