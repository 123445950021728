import axios from "axios";
import to from "./to.js";

export default {
  getFormStructure(name) {
    return to(axios.get(`meta/modules/${name}/layout`));
  },
  // getColumns(name) {
  //   return to(axios.get(`meta/modules/${name}/tab`));
  // },
  registryDiagnoses() {
    return to(axios.get("meta/diagnosis_diagnosis_categories/"));
  },
  diagnosisDiagnosisCategories() {
    return to(axios.get("meta/diagnosis_diagnosis_categories/"));
  },
  diagnoses() {
    return to(axios.get("meta/diagnoses/"));
  },
  // visitTypes() {
  //   return to(axios.get("meta/visit/types"));
  // },
  // modulesForVisitType(type) {
  //   return to(axios.get(`meta/visit/type/${type}`));
  // },
  // visitVariables() {
  //   return to(axios.get(`meta/modules/?acc_action=visit_variable`));
  // },
  visitModules() {
    return to(axios.get('meta/visit'));
  },
  loadOptions() {
    return to(axios.get(`meta/options`));
  },
  studies() {
    return to(axios.get('meta/studies'));
  },
};
