<template>
  <div>
    <date-picker
      class="inline-block flex h-full"
      :value="value"
      :locale="locale"
      :available-dates="availableDates"
      :mode="'date'"
      v-on="inputListeners"
    >
      <template v-if="title !== ''" v-slot:footer>
        <div class="bg-gray-100 text-center p-1 border-t rounded-b-lg">
          {{ title }}
        </div>
      </template>
      <template v-slot="{ inputValue, inputEvents, togglePopover }">
        <div class="flex items-center">
          <input
            :id="name"
            ref="inputel"
            v-on="inputListeners"
            type="text"
            autocomplete="off"
            class="border border-gray-300 rounded-sm px-1 block focus:outline-none focus:ring ring-indigo-200"
            :value="dateValue"
            :disabled="disabled"
          />
          <input v-on="inputEvents" :value="inputValue" :readonly="disabled" :disabled="disabled" class="hidden" />
          <button
            :disabled="disabled"
            tabindex="-1"
            class="p-1 bg-blue-50 border border-blue-100 rounded-l focus:outline-none"
            :class="{
              'hover:bg-blue-200 focus:bg-blue-500 focus:text-white focus:border-blue-500': !disabled,
              'cursor-not-allowed': disabled,
            }"
            @click.prevent="togglePopover()"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="w-4 h-4 fill-current">
              <path
                d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
              ></path>
            </svg>
          </button>
        </div>
      </template>
    </date-picker>
  </div>
</template>

<script>
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import { isDate } from "lodash";
import { formatISO, isValid, parseISO } from "date-fns";

import { dateFormat } from "@/utils/date";

export default {
  name: "CmApproxDatepicker2",
  components: {
    DatePicker,
  },
  props: {
    value: [String, Date],
    approx: String,
    name: String,
    disabled: Boolean,
    label: String,
    locale: {
      type: String,
      default: "sv",
    },
    errorclass: Object,
    classes: Object,
    maxDate: [Date, null],
    minDate: [Date, null],
    ceil: {
      type: Boolean,
      default: false,
    },
    valueType: {
      type: String,
      default: "date",
    },
    title: {
      tyoe: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    availableDates() {
      return {
        start: this.minDate ? this.minDate.getTime() : null,
        end: this.maxDate ? this.maxDate.getTime() : Date.now(),
      };
    },
    dateValue() {
      if (!this.value) return undefined;

      return dateFormat(this.value, this.inputFormat);
    },
    datepickerValue() {
      if (!this.value) return undefined;

      return parseISO(this.value);
    },
    dateFormat() {
      switch (this.approx) {
        case "Y":
          return "YYYY";
        case "M":
          return "YYYY-MM";
        default:
          return "YYYY-MM-DD";
      }
    },
    inputFormat() {
      switch (this.approx) {
        case "Y":
          return "y";
        case "M":
          return "y-MM";
        default:
          return "y-MM-dd";
      }
    },
    inputListeners() {
      return Object.assign({}, this.$listeners, {
        input: event => {
          if (Date.parse(event)) {
            //If using datepicker
            this.$emit("approx", null);
            this.$emit("input", isDate(event) ? event.toLocaleDateString("sv-SE") : event);
          } else if (
            ([4, 7, 10].indexOf(event.target.value.length) != -1 && isValid(new Date(event.target.value))) ||
            !event.target.value
          ) {
            if (isValid(new Date(event.target.value))) {
              var date = new Date(event.target.value);
              if (this.ceil) {
                switch (event.target.value.length) {
                  case 4:
                    date.setMonth(11, 31);
                    date = date > new Date() ? new Date(new Date().setHours(0, 0, 0, 0)) : date;
                    break;
                  case 7:
                    date.setDate(new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate());
                    date = date > new Date() ? new Date(new Date().setHours(0, 0, 0, 0)) : date;
                    break;
                }
              }

              if (
                date > this.maxDate ||
                date < this.minDate.setHours(0, 0, 0, 0) ||
                new Date(event.target.value) > new Date()
              ) {
                date = null;
              }
            }

            switch (event.target.value.length) {
              case 4:
                this.$emit("approx", "Y");
                break;
              case 7:
                this.$emit("approx", "M");
                break;
              default:
                this.$emit("approx", null);
            }

            this.$emit("input", isDate(date) ? date.toLocaleDateString("sv-SE") : null);
          }
        },
      });
    },
  },
  methods: {
    disabledDates(date) {
      return (
        formatISO(date, { representation: "date" }) >
          formatISO(this.calculatedDisabledDates.after, { representation: "date" }) ||
        formatISO(date, { representation: "date" }) <
          formatISO(this.calculatedDisabledDates.before, { representation: "date" })
      );
    },
  },
};
</script>

<style scoped>
.approx-datepicker-wrapper >>> input {
  display: none;
}

.approx-datepicker-wrapper {
  margin-top: -26px;
}
</style>