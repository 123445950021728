
const { utcToZonedTime, format } = require('date-fns-tz')
import { enGB, sv, es } from 'date-fns/locale'

const locales = { enGB, sv, es }

// by providing a default string of 'PP' or any of its variants for `formatStr`
// it will format dates in whichever way is appropriate to the locale
/*
export default function (date, locale = 'sv', formatStr = 'PP') {
    return format(date, formatStr, {
        locale: locales[locale] // or global.__localeId__
    })
}
 */

const dateFormat = (date, formatStr = 'yyyy-MM-dd', locale = 'sv') => {
    if (date instanceof Date === false) {
        date = new Date(date);
    }
    return format(date, formatStr, {
        locale: locales[locale] || sv // or global.__localeId__
    })
}

const dateAndTimeFormat = (date, formatStr = 'yyyy-MM-dd HH:mm:ss', locale = 'sv') => {
    if (date instanceof Date === false) {
        date = new Date(date);
    }
    const timeZone = 'Europe/Stockholm'
    const zonedDate = utcToZonedTime(date, timeZone)

    return format(zonedDate, formatStr, {
        locale: locales[locale] || sv // or global.__localeId__
    })
}

export {
    dateFormat,
    dateAndTimeFormat
}