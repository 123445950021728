export default [
  {
    id: 1,
    pid: null,
    token: null,
    email: 'max@carmona.se',
    hsa_id: null,
    first_name: null,
    last_name: null,
    mobile_phone: null,
    password: 'foobar',
    last_login: null,
    settings: null,
    enabled: null,
    main_unit: null,
    created_at: null,
    created_by: null,
    updated_at: null,
    updated_by: null,
    unitIds: [1, 4],
    registryIds: [1, 2, 3, 4],
  },
  {
    id: 2,
    pid: null,
    token: null,
    email: 'hakan@carmona.se',
    hsa_id: null,
    first_name: null,
    last_name: null,
    mobile_phone: null,
    password: 'foobar',
    last_login: null,
    settings: null,
    enabled: null,
    main_unit: null,
    created_at: null,
    created_by: null,
    updated_at: null,
    updated_by: null,
    unitIds: [1, 4],
    registryIds: [1, 2, 3, 4],
  }
]