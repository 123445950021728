import axios from "axios";
import to from "./to.js";

export default {
  loginSiths() {
    return to(
      axios.post("security/siths", { callback: "/login/siths-verified" })
    );
  },
  verifySiths(data) {
    return to(axios.post("security/siths/verify", data));
  },
  loginCheck(payload) {
    return to(axios.post("login_check", payload));
  },
  sendOtp(method) {
    return to(axios.post("security/otp/send", method));
  },
  verifyOtp(otp) {
    return to(axios.post("security/otp/verify", otp));
  },
  loginBankid(ssn) {
    return to(
      axios.post("security/bankid", { ssn: ssn, callback: "/pm/bankid-verified" })
    );
  },
  verifyBankid(data) {
    return to(axios.post("security/bankid/verify", data));
  }
};
