export default [
  {
    "id": 2,
    "drug": "Orkambi",
    "drug_id": "20141204000077",
    "substance_group": "Lumakaftor + ivakaftor",
    "substance_group_id": 2023,
    "ordination_date": "2010-10-16T00:00:00+01:00",
    "start_date": null,
    "end_date": null,
    "end_cause": null,
    "comment": "Foobar",
    "doses": [
      {
        "id": 1,
        "date": "2010-11-16T00:00:00+01:00",
        "dose": "600",
        "numeric_unit": "mg",
        "administration": null,
        "schedule": "d",
        "frequence": "1",
        "batchnr": null
      },
    ]
  },
]