export default [
  {
    id: 1,
    name: "Psoriasis vulgaris",
    code: "L40.0",
    guidelines: null,
  }, {
    id: 2,
    name: "Aktinisk keratos",
    code: "L57.0",
    guidelines: null,
  }, {
    id: 3,
    name: "Skivepitelcancer i huden",
    code: "C44.9S",
    guidelines: null,
  }, {
    id: 4,
    name: "Kontrollundersökning skivepitelcancer i huden",
    code: "Z08.9B ",
    guidelines: null,
  }, {
    id: 5,
    name: "Basalcellscancer",
    code: "C44.9E",
    guidelines: null,
  }, {
    id: 6,
    name: "Kontrollundersökning basalcellscancer",
    code: "Z08.9A ",
    guidelines: null,
  }, {
    id: 7,
    name: "Nevus",
    code: "D22.9X",
    guidelines: null,
  }, {
    id: 8,
    name: "Dysplastiskt nevus",
    code: "D22.9F",
    guidelines: null,
  }, {
    id: 9,
    name: "Malignt melanom i huden",
    code: "C43.9X ",
    guidelines: null,
  }, {
    id: 10,
    name: "Kontrollundersökning malignt melanom i huden",
    code: "Z08.9C ",
    guidelines: null,
  }, {
    id: 11,
    name: "Allergisk rinit orsakad av pollen",
    code: "J30.1",
    guidelines: null,
  }, {
    id: 12,
    name: "Angina pectoris, ospecificerad",
    code: "I20.9",
    guidelines: null,
  }, {
    id: 13,
    name: "Astma ospecificerad",
    code: "J45.9",
    guidelines: null,
  }, {
    id: 14,
    name: "Bipolär sjukdom ospecificerad",
    code: "F31.9",
    guidelines: null,
  }, {
    id: 15,
    name: "Blandad hyperlipidemi ospecificerad",
    code: "E78.2X",
    guidelines: null,
  }, {
    id: 16,
    name: "Blandade ångest- och depressionstillstånd",
    code: "F41.2",
    guidelines: null,
  }, {
    id: 17,
    name: "Depressiv episod ospecificerad",
    code: "F32.9",
    guidelines: null,
  }, {
    id: 18,
    name: "Diabetes mellitus typ 1 utan (uppgift om) komplika",
    code: "E10.9",
    guidelines: null,
  }, {
    id: 19,
    name: "Diabetes mellitus typ 2 med icke specificerade kom",
    code: "E11.8",
    guidelines: null,
  }, {
    id: 20,
    name: "Diabetes mellitus typ 2 utan (uppgift om) komplika",
    code: "E11.9",
    guidelines: null,
  }, {
    id: 21,
    name: "Epilepsi ospecificerad",
    code: "G40.9",
    guidelines: null,
  }, {
    id: 22,
    name: "Essentiell hypertoni",
    code: "I10.9",
    guidelines: null,
  }, {
    id: 23,
    name: "Fetma ospecificerad",
    code: "E66.9",
    guidelines: null,
  }, {
    id: 24,
    name: "Fibromyalgi",
    code: "M79.7 ",
    guidelines: null,
  }, {
    id: 25,
    name: "Förmaksflimmer kroniskt",
    code: "I48.9A",
    guidelines: null,
  }, {
    id: 26,
    name: "Förmaksflimmer paroxysmalt",
    code: "I48.9B",
    guidelines: null,
  }, {
    id: 27,
    name: "Gastrit ospecificerad",
    code: "K29.7",
    guidelines: null,
  }, {
    id: 28,
    name: "Hyperlipidemi ospecifiecrad",
    code: "E78.5",
    guidelines: null,
  }, {
    id: 29,
    name: "Hypertensiv hjärtsjukdom utan hjärtsvikt",
    code: "I11.9",
    guidelines: null,
  }, {
    id: 30,
    name: "Hypotyreos ospecificerad",
    code: "E03.9",
    guidelines: null,
  }, {
    id: 31,
    name: "Isolerad hyperkolesterolemi",
    code: "E78.0",
    guidelines: null,
  }, {
    id: 32,
    name: "Kroniskt obstruktiv lungsjukdom ospecificerad",
    code: "J44.9",
    guidelines: null,
  }, {
    id: 33,
    name: "Migrän ospecificerad",
    code: "G43.9 ",
    guidelines: null,
  }, {
    id: 34,
    name: "Osteoporos ospecificerad",
    code: "M81.9 ",
    guidelines: null,
  }, {
    id: 35,
    name: "Psoriasis med ledsjukdom",
    code: "L40.5",
    guidelines: null,
  }, {
    id: 36,
    name: "Psoriasisartrit med annan eller ospecificerad loka",
    code: "M07.3X",
    guidelines: null,
  }, {
    id: 37,
    name: "Recidiverande depression ospecificerad",
    code: "F33.9",
    guidelines: null,
  }, {
    id: 38,
    name: "Sekundär hypertoni ospecificerad",
    code: "I15.9",
    guidelines: null,
  }, {
    id: 39,
    name: "Ulcerös kolit ospecificerad",
    code: "K51.9",
    guidelines: null,
  }, {
    id: 40,
    name: "Vitiligo",
    code: "L80.9",
    guidelines: null,
  }, {
    id: 41,
    name: "Diabetes mellitus typ 1 utan (uppgift om) komplika",
    code: "E10.9",
    guidelines: null,
  }, {
    id: 42,
    name: "Diabetes mellitus typ 2 med icke specificerade kom",
    code: "E11.8",
    guidelines: null,
  }, {
    id: 43,
    name: "Diabetes mellitus typ 2 utan (uppgift om) komplika",
    code: "E11.9",
    guidelines: null,
  }, {
    id: 44,
    name: "Psoriasisartrit med annan eller ospecificerad loka",
    code: "M07.3X",
    guidelines: null,
  }, {
    id: 45,
    name: "Diabetes mellitus typ 1 utan (uppgift om) komplika",
    code: "E10.9",
    guidelines: null,
  }, {
    id: 46,
    name: "Diabetes mellitus typ 2 med icke specificerade kom",
    code: "E11.8",
    guidelines: null,
  }, {
    id: 47,
    name: "Diabetes mellitus typ 2 utan (uppgift om) komplika",
    code: "E11.9",
    guidelines: null,
  }, {
    id: 48,
    name: "Psoriasisartrit med annan eller ospecificerad loka",
    code: "M07.3X",
    guidelines: null,
  }, {
    id: 49,
    name: "Diabetes mellitus typ 1 utan (uppgift om) komplikationer",
    code: "E10.9",
    guidelines: null,
  }, {
    id: 50,
    name: "Diabetes mellitus typ 2 med icke specificerade komplikationer",
    code: "E11.8",
    guidelines: null,
  }, {
    id: 51,
    name: "Diabetes mellitus typ 2 utan (uppgift om) komplikationer",
    code: "E11.9",
    guidelines: null,
  }, {
    id: 52,
    name: "Psoriasisartrit med annan eller ospecificerad lokalisation",
    code: "M07.3X",
    guidelines: null,
  }, {
    id: 53,
    name: "Angina pectoris ospecificerad",
    code: "I20.9",
    guidelines: null,
  }, {
    id: 54,
    name: "Alkoholberoendesyndrom ospecificerad",
    code: "F10.2X",
    guidelines: null,
  }, {
    id: 55,
    name: "Allergi ospecificerad",
    code: "T78.4 ",
    guidelines: null,
  }, {
    id: 56,
    name: "Allergisk rinit ospecificerad",
    code: "J30.4",
    guidelines: null,
  }, {
    id: 57,
    name: "Alopecia areata ospecificerad",
    code: "L63.9",
    guidelines: null,
  }, {
    id: 58,
    name: "Anemi ospecificerad",
    code: "D64.9",
    guidelines: null,
  }, {
    id: 59,
    name: "Annan polymorf ljusdermatit",
    code: "L56.4W ",
    guidelines: null,
  }, {
    id: 60,
    name: "Artros ospecificerad",
    code: "M19.9",
    guidelines: null,
  }, {
    id: 61,
    name: "Atoxisk struma ospecificerad",
    code: "E04.9 ",
    guidelines: null,
  }, {
    id: 62,
    name: "Autoimmun hepatit",
    code: "K75.4 ",
    guidelines: null,
  }, {
    id: 63,
    name: "Celiaki glutenenteropati UNS",
    code: "K90.0X ",
    guidelines: null,
  }, {
    id: 64,
    name: "Crohns sjukdom ospecificerad",
    code: "K50.9 ",
    guidelines: null,
  }, {
    id: 65,
    name: "Diabetes mellitus ospecificerad",
    code: "E14.9 ",
    guidelines: null,
  }, {
    id: 66,
    name: "Fibrosarkom",
    code: "C49.9E",
    guidelines: null,
  }, {
    id: 67,
    name: "Gammal hjärtinfarkt",
    code: "I25.2 ",
    guidelines: null,
  }, {
    id: 68,
    name: "Gikt ospecificerad",
    code: "M10.9 ",
    guidelines: null,
  }, {
    id: 69,
    name: "Hemipares ospecificerad",
    code: "G81.9 ",
    guidelines: null,
  }, {
    id: 70,
    name: "Hjärtinsufficiens ospecificerad",
    code: "I50.9 ",
    guidelines: null,
  }, {
    id: 71,
    name: "Hypertoni ospec",
    code: "I10.9",
    guidelines: null,
  }, {
    id: 72,
    name: "Konjunktivit allergisk UNS",
    code: "H10.1X ",
    guidelines: null,
  }, {
    id: 73,
    name: "Kotkompression som ej klassificeras annorstädes",
    code: "M48.5",
    guidelines: null,
  }, {
    id: 74,
    name: "Kronisk ischemisk hjärtsjukdom ospecificerad",
    code: "I25.9 ",
    guidelines: null,
  }, {
    id: 75,
    name: "Kronisk njursvikt ospecificerad",
    code: "N18.9 ",
    guidelines: null,
  }, {
    id: 76,
    name: "Kronisk virushepatit ospecificerad steatos",
    code: "B18.9H ",
    guidelines: null,
  }, {
    id: 77,
    name: "Laktosintolerans ospecificerad",
    code: "E73.9 ",
    guidelines: null,
  }, {
    id: 78,
    name: "Levercirros orsakad av alkohol",
    code: "K70.3 ",
    guidelines: null,
  }, {
    id: 79,
    name: "Leverfibros",
    code: "K74.0 ",
    guidelines: null,
  }, {
    id: 80,
    name: "Lichen ruber planus ospecificerad",
    code: "L43.9",
    guidelines: null,
  }, {
    id: 81,
    name: "Lindrig psykisk utvecklingsstörning",
    code: "F70.9",
    guidelines: null,
  }, {
    id: 82,
    name: "Lungsarkoidos",
    code: "D86.0",
    guidelines: null,
  }, {
    id: 83,
    name: "Malign tumör i bröst ospecificerad",
    code: "C50.9",
    guidelines: null,
  }, {
    id: 84,
    name: "Malign tumör i prostata",
    code: "C61.9",
    guidelines: null,
  }, {
    id: 85,
    name: "Malign tumör i urinblåsan",
    code: "C67.9",
    guidelines: null,
  }, {
    id: 86,
    name: "Malignt melanom ospecifierat",
    code: "C43.9X",
    guidelines: null,
  }, {
    id: 87,
    name: "Ménières sjukdom",
    code: "H81.0 ",
    guidelines: null,
  }, {
    id: 88,
    name: "Njursten",
    code: "N20.0 ",
    guidelines: null,
  }, {
    id: 89,
    name: "Parkinsons sjukdom",
    code: "G20.9",
    guidelines: null,
  }, {
    id: 90,
    name: "Rosacea ospecificerad",
    code: "L71.9",
    guidelines: null,
  }, {
    id: 91,
    name: "Sekundär trombocytopeni",
    code: "D69.5 ",
    guidelines: null,
  }, {
    id: 92,
    name: "Sjukdom i sköldkörteln ospecificerad",
    code: "E07.9 ",
    guidelines: null,
  }, {
    id: 93,
    name: "Sjukdom i tarmen ospecificerad",
    code: "K63.9 ",
    guidelines: null,
  }, {
    id: 94,
    name: "Trigeminusneuralgi",
    code: "G50.0 ",
    guidelines: null,
  }, {
    id: 95,
    name: "Ångesttillstånd ospecificerat",
    code: "F41.9",
    guidelines: null,
  }, {
    id: 96,
    name: "Annan derma diagnos",
    code: "Annan",
    guidelines: null,
  }, {
    id: 97,
    name: "Kontaktallergi",
    code: "X00.0",
    guidelines: null,
  }, {
    id: 98,
    name: "Pemphigus vulgaris",
    code: "L10.0 ",
    guidelines: null,
  }, {
    id: 99,
    name: "Pemphigus vegetans",
    code: "L10.1 ",
    guidelines: null,
  }, {
    id: 100,
    name: "Pemphigus foliaceus",
    code: "L10.2 ",
    guidelines: null,
  }, {
    id: 101,
    name: "Pemphigus erythematosus",
    code: "L10.4 ",
    guidelines: null,
  }, {
    id: 102,
    name: "Läkemedelsutlöst pemfigus",
    code: "L10.5 ",
    guidelines: null,
  }, {
    id: 103,
    name: "Annan specificerad pemfigus",
    code: "L10.8 ",
    guidelines: null,
  }, {
    id: 104,
    name: "Pemfigus ospecificerad paraneoplastisk",
    code: "L10.9 ",
    guidelines: null,
  }, {
    id: 105,
    name: "Familjär benign pemfigus (Hailey-Hailey)",
    code: "Q82.8D ",
    guidelines: null,
  }, {
    id: 106,
    name: "Okulär pemfigoid",
    code: "H13.3* ",
    guidelines: null,
  }, {
    id: 107,
    name: "Bullös pemfigoid",
    code: "L12.0 ",
    guidelines: null,
  }, {
    id: 108,
    name: "Generaliserad bullös pemfigoid",
    code: "L12.0A ",
    guidelines: null,
  }, {
    id: 109,
    name: "Lokaliserad bullös pemfigoid",
    code: "L12.0B ",
    guidelines: null,
  }, {
    id: 110,
    name: "Annan bullös pemfigoid",
    code: "L12.0W ",
    guidelines: null,
  }, {
    id: 111,
    name: "Ärrbildande pemfigoid",
    code: "L12.1 ",
    guidelines: null,
  }, {
    id: 112,
    name: "Slemhinnepemfigoid UNS",
    code: "L12.1A ",
    guidelines: null,
  }, {
    id: 113,
    name: "Generaliserad ärrbildande pemfigoid",
    code: "L12.1B ",
    guidelines: null,
  }, {
    id: 114,
    name: "Lokaliserad ärrbildande pemfigoid (kronisk) ",
    code: "L12.1C ",
    guidelines: null,
  }, {
    id: 115,
    name: "Annan ärrbildande pemfigoid",
    code: "L12.1W ",
    guidelines: null,
  }, {
    id: 116,
    name: "Juvenil pemfigoid",
    code: "L12.2C ",
    guidelines: null,
  }, {
    id: 117,
    name: "Annan specificerad pemfigoid",
    code: "L12.8 ",
    guidelines: null,
  }, {
    id: 118,
    name: "Pemfigoid ospecificerad",
    code: "L12.9 ",
    guidelines: null,
  }, {
    id: 119,
    name: "Epidermolysis bullosa acquisita",
    code: "L12.3",
    guidelines: null,
  }, {
    id: 120,
    name: "Dermatitis herpetiformis",
    code: "L13.0",
    guidelines: null,
  }, {
    id: 121,
    name: "Lichen planus pemfigoides",
    code: "L43.1",
    guidelines: null,
  }, {
    id: 122,
    name: "Allergisk urtikaria",
    code: "L50.0",
    guidelines: null,
  }, {
    id: 123,
    name: "Kronisk urtikaria (spontan)",
    code: "L50.8",
    guidelines: null,
  }, {
    id: 124,
    name: "Kronisk inducerbar",
    code: "UR.001",
    guidelines: null,
  }, {
    id: 125,
    name: "Urtikaria orsakad av kyla eller värme",
    code: "L50.2",
    guidelines: null,
  }, {
    id: 126,
    name: "Solurtikaria",
    code: "L56.3",
    guidelines: null,
  }, {
    id: 127,
    name: "Dermografisk urtikaria",
    code: "L50.3",
    guidelines: null,
  }, {
    id: 128,
    name: "Vibrationsutlöst urtikaria",
    code: "L50.4",
    guidelines: null,
  }, {
    id: 129,
    name: "Kolinergisk urtikaria",
    code: "L50.5",
    guidelines: null,
  }, {
    id: 130,
    name: "Angioödem",
    code: "T78.3",
    guidelines: null,
  }, {
    id: 131,
    name: "Diskoid lupus erythematosus",
    code: "L93.0",
    guidelines: null,
  }, {
    id: 132,
    name: "Atopiskt eksem",
    code: "L20.0",
    guidelines: null,
  }
]