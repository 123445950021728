<template>
  <model-list-select
    v-if="options"
    :list="options"
    :option-value="valueProp"
    :option-text="name"
    :placeholder="placeholder || '...'"
    v-model="selectedOption"
    @input="$emit('select', selectedOption)"
  ></model-list-select>
</template>

<script>
import "vue-search-select/dist/VueSearchSelect.css";
import { ModelListSelect } from "vue-search-select";

export default {
  name: "CmSearchSelect",
  props: {
    placeholder: String,
    options: Array,
    name: String,
    valueProp: [String, Number],
    selected: Number,
  },
  components: {
    ModelListSelect,
  },
  data() {
    return {
      loaded: false,
      selectedOption: this.selected || null,
    };
  },
};
</script>
