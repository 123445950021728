import axios from "axios";
import to from "./to.js";

export default {
  load(name) {
    return to(axios.all([
      axios.get(`patient/forms/${name}`),
      axios.get(`meta/modules/${name}/layout`),
      axios.get(`meta/modules/${name}/tab`),
    ]).then(axios.spread((data, layout, tab) => {
      return { data: data.data, layout: layout.data, tab: tab.data }
    })))
  },

  // load(name) {
  //   return to(axios.get(`patient/forms/${name}`));
  // },
  getTabData(name) {
    return to(axios.get(`patient/tabs/${name}`));
  },
  getLabData() {
    return to(axios.get("patient/lab"));
  },
  getLayout(name) {
    return to(axios.get(`meta/modules/${name}/layout`));
  },
  getTherapyData() {
    return to(axios.get("patient/therapies"));
  }
};
