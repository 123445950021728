import { getField, updateField } from "vuex-map-fields";
// import router from "@/router";
import ApiAuth from "@/api/auth";
import ApiUser from "@/api/user";

const initialState = () => {
  const token = typeof (localStorage.getItem("_token")) !== 'undefined' ? localStorage.getItem("_token") : null;

  return {
    token: token,
    grandidsession: null,
    username: null,
    password: null,
    ssn: null,
    otpMethods: null,
    otpMethod: null,
    unitList: null,
    currentUnit: null,
    currentUnitName: null,
    authenticated: true,
    hsaid: null,
    otp: null,
    errors: {
      siths: null,
      username: null,
      ssn: null,
      otpsend: null,
      otpverify: null,
      pm: null
    },
  };
};

const state = initialState;

const SET_GRANDIDSESSION = "SET_GRANDIDSESSION";
const SET_TOKEN = "SET_TOKEN";
const SET_OTPMETHODS = "SET_OTPMETHODS";
const SET_OTP = "SET_OTP";
const RESET_STATE = "RESET_STATE";
const SET_ERROR = "SET_ERROR";
const SET_UNITLIST = "SET_UNITLIST";
const SET_AUTHENTICATED = "SET_AUTHENTICATED";

const actions = {
  resetState({ commit }) {
    commit(RESET_STATE);
  },
  refreshToken({ commit }, token) {
    commit(SET_TOKEN, token);
    localStorage.setItem("_token", token);
  },
  async loginSiths({ commit }) {
    localStorage.removeItem("_token");
    const [error, response] = await ApiAuth.loginSiths();

    if (!error) {
      commit(SET_GRANDIDSESSION, response.data.sessionId);
      const url = new URL(response.data.redirectUrl);
      return url.href;
    }
  },
  async verifySiths({ commit, dispatch, state }) {
    const payload = { grandidsession: localStorage.getItem("grandidsession") };

    const [error, response] = await ApiAuth.verifySiths(payload);
    if (error) {
      commit(SET_ERROR, { key: "siths", message: error.response.data.message });

      return Promise.reject(error);
    }

    commit(SET_TOKEN, response.data.token);
    commit(SET_AUTHENTICATED, true);

    await dispatch("getUnitList");
    await dispatch("user/getUserInfo", null, { root: true });

    if (state.unitList.length === 1) {
      await dispatch('setCurrentUnit', state.unitList[0].id);
    }
  },
  async loginCheck({ commit, state }) {
    localStorage.removeItem("_token");
    commit(SET_ERROR, { key: "siths", message: null });
    commit(SET_ERROR, { key: "username", message: null });
    commit(SET_ERROR, { key: "otpsend", message: null });
    commit(SET_ERROR, { key: "otpverify", message: null });

    const [error, response] = await ApiAuth.loginCheck({ username: state.username, password: state.password });
    if (error !== null) {
      commit(SET_ERROR, { key: "username", message: error.response.data.message });
      return Promise.reject(error)
    }

    commit(SET_TOKEN, response.data.token);

    const responseMethods = response.data.otp.methods;
    const otpMethods = Object.keys(responseMethods).filter(d => responseMethods[d]);

    commit(SET_OTPMETHODS, otpMethods);

  },
  async sendOtp({ commit, state }) {
    const [error, response] = await ApiAuth.sendOtp({ method: state.otpMethod });
    if (error !== null) {
      commit(SET_ERROR, { key: "otpsend", message: error.response.data.message });

      return Promise.reject(error);
    }

    if (response.data.key) {
      commit(SET_OTP, response.data.key);
    }
  },
  async verifyOtp({ commit, dispatch, state }) {
    const [error, response] = await ApiAuth.verifyOtp({ key: state.otp });

    if (!error) {
      commit(SET_AUTHENTICATED, true);

      await dispatch("getUnitList"); //wait for unitlist so that we can redirect the user directly home or present a choice of units
      await dispatch("user/getUserInfo", null, { root: true });

      if (state.unitList.length === 1) {
        await dispatch('setCurrentUnit', state.unitList[0].id);
      }
    }

    if (error || response.data.success === false) {
      commit(SET_ERROR, { key: "otpverify", message: "login.otperror" });
      return Promise.reject(error);
    }
  },
  async setCurrentUnit({ commit, dispatch, rootState }, unitId) {
    const [error] = await ApiUser.setCurrentUnit({ unit: unitId });
    if (!error) {
      const unitName = rootState.userExtra.unitList.find(d => d.id === unitId);

      commit("userExtra/SET_CURRENT_UNIT_ID", unitId, { root: true });
      commit("userExtra/SET_CURRENT_UNIT_NAME", unitName.name, { root: true });

      await dispatch("user/getUserInfo", null, { root: true });
    }
  },
  async getUnitList({ commit }) {
    const [error, response] = await ApiUser.unitList();
    if (!error) {
      const unitlist = response.data
      commit(SET_UNITLIST, unitlist);
      commit("userExtra/SET_UNIT_LIST", unitlist, { root: true });
      return;
    }
  },
  async loginBankid({ commit, state }) {
    commit(SET_ERROR, { key: "pm", message: null });

    const ssn = state.ssn.replace('-', '');
    const [error, response] = await ApiAuth.loginBankid(ssn);

    if (!error) {
      commit(SET_GRANDIDSESSION, response.data.sessionId);

      const url = new URL(response.data.redirectUrl);
      return url.href;
    }
  },
  //eslint-disable-next-line
  async verifyBankid({ commit, dispatch }) {

    const payload = { grandidsession: localStorage.getItem("grandidsession") };

    const [error, response] = await ApiAuth.verifyBankid(payload);

    if (error) {

      commit(SET_ERROR, { key: 'pm', message: error.response.data.message })
      return Promise.reject(error);
    }

    var jwtDecode = require('jwt-decode');
    var decoded = jwtDecode(response.data.token);

    await dispatch('pm/setPmForms', decoded.forms, { root: true });
    await dispatch('pm/setPmData', decoded.pmdata, { root: true });
    await dispatch('pm/setToken', response.data.token, { root: true });


    return { done: true };
  },
  corserror({ commit }) {
    commit(SET_AUTHENTICATED, false);
    return true;
  },
  logout({ dispatch }) {
    dispatch("resetAllState", null, { root: true })
      .catch(e => console.log('caught:', e));
  },
};

const mutations = {
  updateField,
  RESET_STATE(state) {
    localStorage.removeItem("_token");
    localStorage.removeItem("grandidsession");

    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_GRANDIDSESSION(state, value) {
    localStorage.setItem("grandidsession", value);
    state.grandidsession = value;
  },
  SET_TOKEN(state, value) {
    state.token = value;
    state.username = null;
    state.ssn = null;
    state.password = null;
    localStorage.removeItem("grandidsession");
  },
  SET_OTPMETHODS(state, value) {
    state.otpMethods = value;
  },
  SET_ERROR(state, data) {
    state.errors[`${data.key}`] = data.message;
  },
  SET_UNITLIST(state, value) {
    state.otpMethod = null;
    state.otpMethods = null;
    state.otp = null;
    state.unitList = value;
  },
  SET_PM_MODULES(state, value) {
    state.pmModules = value;
  },
  SET_PM_DATA(state, value) {
    state.pmData = value;
  },
  SET_AUTHENTICATED(state, value) {
    state.authenticated = value;
  },
  SET_HSAID(state, value) {
    state.hsaid = value;
  },
  SET_OTP(state, value) {
    state.otp = value;
  }
};

const getters = {
  getField,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
